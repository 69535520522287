import { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import { FormErrorMessage, FormLabel, FormControl } from '@chakra-ui/react';

type FieldWrapperProps = {
  label?: string;
  className?: string;
  children: ReactNode;
  error?: FieldError | undefined;
};

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'className' | 'children'>;

export const FieldWrapper = (props: FieldWrapperProps) => {
  const { label, className, error, children } = props;

  return (
    <FormControl isInvalid={!!error?.message}>
      <FormLabel htmlFor={label} className={className} color="brand.200">
        {label}
      </FormLabel>
      {children}
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
