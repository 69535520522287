import { Icon, IconProps } from '@chakra-ui/react';

const DownloadIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 22 21" {...props}>
      <path
        d="M11.0088 12.3574C11.1846 12.3574 11.3428 12.3047 11.5186 12.1289L14.4805 9.26367C14.6211 9.13184 14.6914 8.98242 14.6914 8.79785C14.6914 8.4375 14.4189 8.18262 14.0498 8.18262C13.8828 8.18262 13.6982 8.25293 13.5752 8.39355L12.2393 9.80859L11.6504 10.4326L11.7031 9.11426V1.2832C11.7031 0.914062 11.3867 0.606445 11.0088 0.606445C10.6309 0.606445 10.3057 0.914062 10.3057 1.2832V9.11426L10.3672 10.4326L9.76953 9.80859L8.43359 8.39355C8.31055 8.25293 8.11719 8.18262 7.9502 8.18262C7.58105 8.18262 7.31738 8.4375 7.31738 8.79785C7.31738 8.98242 7.39648 9.13184 7.52832 9.26367L10.499 12.1289C10.6748 12.3047 10.8242 12.3574 11.0088 12.3574ZM3.39746 20.9268H18.6025C20.4482 20.9268 21.3623 20.0127 21.3623 18.2021V12.999C21.3623 12.1992 21.2568 11.8037 20.8613 11.3291L17.8291 7.56738C16.8008 6.30176 16.168 5.56348 14.6123 5.56348H13.3203V6.80273H14.709C15.3506 6.80273 16.0186 7.36523 16.4492 7.91016L19.5693 11.8389C19.9209 12.2871 19.7979 12.4629 19.3145 12.4629H13.8916C13.3994 12.4629 13.1709 12.8408 13.1709 13.2275V13.2627C13.1709 14.3438 12.3271 15.4775 11 15.4775C9.68164 15.4775 8.83789 14.3438 8.83789 13.2627V13.2275C8.83789 12.8408 8.60059 12.4629 8.1084 12.4629H2.70312C2.19336 12.4629 2.09668 12.2607 2.43945 11.8389L5.55078 7.91016C5.98145 7.36523 6.64941 6.80273 7.29102 6.80273H8.68848V5.56348H7.39648C5.83203 5.56348 5.20801 6.30176 4.17969 7.56738L1.13867 11.3291C0.751953 11.8037 0.637695 12.1992 0.637695 12.999V18.2021C0.637695 20.0127 1.56055 20.9268 3.39746 20.9268Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default DownloadIcon;
