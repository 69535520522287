import { Box, Heading, Button, HStack, Slide, Link } from '@chakra-ui/react';
import WhatsAppIcon from 'assets/icons/WhatsAppIcon';
import FacebookIcon from 'assets/icons/FacebookIcon';
import TwitterIcon from 'assets/icons/twitterIcon';
import { useEffect } from 'react';

type ShareScreenProps = {
  isOpen: boolean;
  onToggle: () => void;
  articleTitle: string;
  onLike: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isCopied: boolean;
};

export const ShareScreen = ({
  isOpen,
  onToggle,
  articleTitle,
  onLike,
  isCopied,
}: ShareScreenProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Box
          position="absolute"
          top="0"
          w="full"
          zIndex="9"
          bg="brand.500"
          height="100%"
          opacity="0.4"
          onClick={onToggle}
        />
      )}
      <Slide direction="bottom" in={isOpen} style={{ zIndex: 10 }}>
        <Box
          bg="brand.50"
          zIndex="3"
          w="full"
          minHeight="25vh"
          borderTopLeftRadius="20px"
          borderTopRightRadius="20px"
          p="6"
        >
          <Heading size="md" color="brand.200">
            Sharing is caring
          </Heading>
          <HStack color="brand.50" fontSize="12px" justifyContent="space-between" mt="5">
            <Link
              href={`whatsapp://send?text=Habe grade den Artikel zum Thema ${articleTitle} gelesen. Schau ihn dir in der Novaheal-App hier an: ${window.location.href}`}
              data-action="share/whatsapp/share"
              fontSize="12px"
              w="110px"
              bg="#128C7E"
              py="3"
              px="1"
              borderRadius="5px"
              textAlign="center"
            >
              <WhatsAppIcon mr="2px" mb="2px" /> WhatsApp
            </Link>
            <Link
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              fontSize="12px"
              w="100px"
              bg="#4267B2"
              borderRadius="5px"
              py="3"
              px="1"
              textAlign="center"
            >
              <FacebookIcon mr="2px" mb="2px" /> Facebook
            </Link>
            <Link
              href={`https://twitter.com/intent/tweet?text=Habe%20grade%20den%20Artikel%20zum%20Thema%20${articleTitle}%20gelesen-%20Schau%20ihn%20dir%20in%20der%20Novaheal%20App%20hier%20an%20${window.location.href}`}
              fontSize="12px"
              w="80px"
              bg="#1DA1F2"
              borderRadius="5px"
              py="3"
              px="1"
              textAlign="center"
            >
              <TwitterIcon mr="2px" mb="2px" /> Twitter
            </Link>
            <Link
              href={`mailto:?body=Habe grade den Artikel zum Thema ${articleTitle} gelesen. Schau ihn dir in der Novaheal-App hier an: ${window.location.href}&subject=Novaheal`}
              fontSize="12px"
              w="60px"
              bg="brand.200"
              borderRadius="5px"
              py="3"
              px="1"
              textAlign="center"
            >
              Mail
            </Link>
          </HStack>
          <Button w="100%" mt="4" fontSize="12px" color="brand.200" onClick={onLike}>
            {isCopied ? 'Link kopiert' : 'Link kopieren'}
          </Button>
        </Box>
      </Slide>
    </>
  );
};
