import { axios } from 'lib/axios';
import { useMutation } from 'react-query';
import { useAuth } from 'lib/auth';

const addVoucherForUser = async (voucher: string) => {
  await axios.patch('/user/update/voucher', {
    voucher,
  });
};
export const useUpdateUser = () => {
  const { refetchUser } = useAuth();
  return useMutation({
    onSuccess: () => {
      refetchUser();
    },
    mutationFn: addVoucherForUser,
  });
};
