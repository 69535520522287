import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password';
  className?: string;
  placeholder?: string;
  registration: Partial<UseFormRegisterReturn>;
  rightElement?: React.ReactNode;
  isDisabled?: boolean;
};

export const InputField = (props: InputFieldProps) => {
  const {
    type,
    label,
    className,
    registration,
    error,
    placeholder,
    rightElement,
    isDisabled = false,
  } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <InputGroup>
        <Input
          type={type}
          className={className}
          isDisabled={isDisabled}
          {...registration}
          placeholder={placeholder}
          bg="brand.50"
          color="brand.200"
          border="1px solid"
          borderColor="brand.500"
        />
        {rightElement && <InputRightElement width="4.5rem">{rightElement}</InputRightElement>}
      </InputGroup>
    </FieldWrapper>
  );
};
