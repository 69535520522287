import { AxiosResponse } from 'axios';
import { axios } from 'lib/axios';
import { useMutation } from 'react-query';
import { Feedback } from 'types/content';

export const addFeedbackVoting = async (data: {
  articleId: string;
  votingNumber: number;
}): Promise<AxiosResponse<Feedback>> => {
  return axios.post(`feedback`, data);
};

export const useFeedback = () => {
  return useMutation({
    onSuccess: (data) => {
      window.localStorage.setItem('feedback', data.data.id);
    },
    mutationFn: addFeedbackVoting,
  });
};
