import { useQuery } from 'react-query';
import { axios } from 'lib/axios';

export const addSubscriptionIntent = async (priceId: string): Promise<any> => {
  const subscriptionIntent = await axios.post(`/subscriptions`, { priceId });
  return subscriptionIntent;
};

export const useSubscriptionIntent = (priceId: string) => {
  return useQuery({
    queryKey: ['subscription'],
    queryFn: () => addSubscriptionIntent(priceId),
  });
};
