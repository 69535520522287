import {
  Modal,
  Text,
  useDisclosure,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalBody,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import { Fragment } from 'react';
import { useKeyword } from 'hooks/api/content/keyword/getKeywordByName';
import { ContentPiece } from 'types/content';

type KeywordProps = {
  name: string;
};

const parser = (input: string) => {
  return parse(input, {});
};

export const Keyword = ({ name }: KeywordProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: keyword, isError } = useKeyword(name.trim());

  if (!keyword || isError) return <Text as="span">{name}</Text>;

  return (
    <>
      <Text
        as="span"
        decoration="underline"
        textDecorationStyle="dashed"
        textDecorationColor="brand.200"
        cursor="pointer"
        onClick={onOpen}
      >
        {name}
      </Text>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent w="90%" maxH="90vh">
          <ModalHeader color="brand.200">{name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb="10" overflowY="scroll" px="6">
            {keyword.content &&
              keyword.content.map((singleContentPiece: ContentPiece) => (
                <Fragment key={singleContentPiece.id}>
                  {parser(singleContentPiece.content)}
                </Fragment>
              ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
