import { Button, HStack } from '@chakra-ui/react';
import HeartIcon from 'assets/icons/heartIcon';
import ShareIcon from 'assets/icons/shareIcon';
import DownloadIcon from 'assets/icons/downloadIcon';

type ButtonBarProps = {
  onShare: () => void;
  fileUrl: string | null;
  onLike: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isLiked: boolean;
};

export const ButtonBar = ({ onShare, fileUrl, onLike, isLiked }: ButtonBarProps) => {
  return (
    <HStack bg="brand.50" py="2" px="5" justify="space-evenly">
      <Button
        bg="brand.700"
        color="brand.200"
        size="sm"
        fontSize="sm"
        onClick={onShare}
        w={fileUrl ? '30%' : '50%'}
      >
        <ShareIcon mr="6px" /> Teilen
      </Button>
      {fileUrl && (
        <Button bg="brand.700" w="full" size="sm" fontSize="sm" color="brand.200">
          <DownloadIcon mr="6px" /> PDF Download
        </Button>
      )}
      <Button bg="brand.700" size="sm" fontSize="sm" color="brand.200" w="full" onClick={onLike}>
        <HeartIcon mr="6px" mt="2px" checked={isLiked} /> {isLiked ? 'Gespeichert' : 'Speichern'}
      </Button>
    </HStack>
  );
};
