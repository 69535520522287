import { HStack, Image } from '@chakra-ui/react';
import { useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';

type ArticleImageProps = {
  imgUrl: string;
};

export const ArticleImage = ({ imgUrl }: ArticleImageProps) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  return (
    <>
      <HStack bg="brand.50" py="2" justify="flex-start">
        <Image
          borderRadius="10%"
          border="1px"
          borderColor="gray.200"
          onClick={() => setIsViewerOpen(true)}
          boxSize="100px"
          objectFit="cover"
          src={imgUrl}
          alt=""
        />
      </HStack>
      {isViewerOpen && (
        <ImageViewer
          src={[imgUrl]}
          disableScroll={false}
          closeOnClickOutside
          onClose={() => setIsViewerOpen(false)}
        />
      )}
    </>
  );
};
