import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import { useAuth } from 'lib/auth';
import { ForgotPassword } from 'pages/auth/forgot-password';
import { Login } from 'pages/auth/login';
import { Register } from 'pages/auth/register';
import { ResetPassword } from 'pages/auth/reset-password';
import { NotFound } from '../pages/landing/notFound';

export const AuthRoutes = () => {
  // TODO: check if there is a better method
  const auth = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.user) {
      navigate('/app');
    }
  });
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
    </Routes>
  );
};

export const publicRoutes = [
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
];
