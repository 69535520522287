import { Icon, IconProps } from '@chakra-ui/react';

const KnowledgeIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 29 26" {...props}>
      <path
        d="M27.8809 5.26709L17.3306 0.436035C16.2266 -0.0620117 15.3633 -0.277832 14.5 -0.26123C13.6284 -0.277832 12.7651 -0.0620117 11.6611 0.436035L1.11084 5.26709C0.47998 5.54932 0.172852 6.06396 0.172852 6.57861C0.172852 7.09326 0.47998 7.58301 1.11084 7.87354L5.83398 10.0234L12.4331 6.86914C12.3418 6.76953 12.3003 6.66992 12.3003 6.57031C12.3003 5.94775 13.3628 5.43311 14.5332 5.43311C15.6953 5.43311 16.7495 5.94775 16.7495 6.57031C16.7495 7.21777 15.6953 7.73242 14.5332 7.73242C14.1514 7.73242 13.7944 7.68262 13.4707 7.58301L7.1123 10.6294L11.6611 12.7129C12.7651 13.2192 13.6284 13.4268 14.5 13.4185C15.3633 13.4268 16.2266 13.2192 17.3306 12.7129L27.8809 7.87354C28.5117 7.58301 28.8188 7.09326 28.8188 6.57861C28.8188 6.06396 28.5117 5.54932 27.8809 5.26709ZM7.0791 12.0654V18.5234C8.83887 19.5942 11.3706 20.2915 14.5 20.2915C20.7422 20.2915 24.6602 17.5439 24.6602 14.6055V10.8037L17.8867 13.9082C16.6582 14.4727 15.5625 14.7549 14.5 14.7383C13.4292 14.7549 12.3335 14.4727 11.105 13.9082L7.0791 12.0654ZM4.33154 10.812V14.6055C4.33154 15.5103 4.87109 16.6309 5.87549 17.5854V11.5176L4.33154 10.812ZM5.03711 20.8892V23.728C5.03711 24.5249 5.53516 25.0229 6.32373 25.0229H6.61426C7.41113 25.0229 7.90918 24.5249 7.90918 23.728V20.8892C7.90918 20.2666 7.60205 19.8267 7.0791 19.6689V18.5234C6.62256 18.2412 6.21582 17.9258 5.87549 17.5854V19.6606C5.35254 19.8184 5.03711 20.2666 5.03711 20.8892Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default KnowledgeIcon;
