import { Suspense } from 'react';
import { Outlet } from 'react-router';
import { Navigate } from 'react-router-dom';
import { Spinner, Stack } from '@chakra-ui/react';
import { useAuth } from '../lib/auth';
import { lazyImport } from '../utils/lazyimport';
import ArticlePage from '../pages/in-app/knowledge/article';
import { NotFound } from '../pages/landing/notFound';

const { Dashboard } = lazyImport(() => import('../pages/in-app/dashboard'), 'Dashboard');
const { Wissen } = lazyImport(() => import('../pages/in-app/knowledge'), 'Wissen');
const { Praxisauftraege } = lazyImport(
  () => import('../pages/in-app/assignments'),
  'Praxisauftraege'
);
const { Pflegeplanung } = lazyImport(() => import('../pages/in-app/planning'), 'Pflegeplanung');
const { Favoriten } = lazyImport(() => import('../pages/in-app/favorites'), 'Favoriten');
const { Settings } = lazyImport(() => import('../pages/in-app/settings'), 'Settings');
const { Profile } = lazyImport(() => import('../pages/in-app/settings/profile'), 'Profile');
const { EditProfile } = lazyImport(() => import('../pages/in-app/settings/edit'), 'EditProfile');
const { Subscription } = lazyImport(
  () => import('../pages/in-app/settings/subscription'),
  'Subscription'
);
const { EditSubscription } = lazyImport(
  () => import('../pages/in-app/settings/edit-subscription'),
  'EditSubscription'
);
const { Folder } = lazyImport(() => import('../pages/in-app/knowledge/folder'), 'Folder');
const { FilterPage } = lazyImport(() => import('../pages/in-app/knowledge/filter'), 'FilterPage');
const { SearchResult } = lazyImport(
  () => import('../pages/in-app/knowledge/search'),
  'SearchResult'
);
const { ChangePassword } = lazyImport(
  () => import('../pages/in-app/settings/changePassword'),
  'ChangePassword'
);

const App = () => {
  const auth = useAuth();
  return (
    <Suspense
      fallback={
        <Stack
          align="center"
          width="100vw"
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner
            m="10%"
            thickness="7px"
            speed="1s"
            emptyColor="brand.700"
            color="brand.100"
            size="xl"
          />
        </Stack>
      }
    >
      {auth.user ? <Outlet /> : <Navigate to="/auth/login" />}
    </Suspense>
  );
};
export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      {
        path: '*',
        element: <NotFound buttonText="Was anderes lernen" buttonLink="/app" />,
      },
      {
        path: '/app',
        element: <Dashboard />,
      },
      {
        path: '/app/knowledge',
        element: <Wissen />,
      },
      {
        path: '/app/knowledge/search',
        element: <SearchResult />,
      },
      {
        path: '/app/knowledge/folder/:id',
        element: <Folder />,
      },
      {
        path: '/app/knowledge/:id',
        element: <ArticlePage />,
      },
      {
        path: '/app/knowledge/filter',
        element: <FilterPage />,
      },
      {
        path: '/app/assignments',
        element: <Praxisauftraege />,
      },
      {
        path: '/app/planning',
        element: <Pflegeplanung />,
      },
      {
        path: '/app/favorites',
        element: <Favoriten />,
      },
      {
        path: '/app/settings',
        element: <Settings />,
      },
      {
        path: '/app/settings/profile',
        element: <Profile />,
      },
      {
        path: '/app/settings/password',
        element: <ChangePassword />,
      },
      {
        path: '/app/settings/profile/edit',
        element: <EditProfile />,
      },
      {
        path: '/app/settings/subscription',
        element: <Subscription />,
      },
      {
        path: '/app/settings/edit-subscription',
        element: <EditSubscription />,
      },
    ],
  },
];
