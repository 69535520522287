import { Icon, IconProps } from '@chakra-ui/react';

const HappySmileyIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <path
        d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 4.47 15.5 0 10 0ZM10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM11 7.94L12.06 9L13.12 7.94L14.18 9L15.24 7.94L13.12 5.82L11 7.94ZM6.88 7.94L7.94 9L9 7.94L6.88 5.82L4.76 7.94L5.82 9L6.88 7.94ZM10 15.5C12.33 15.5 14.31 14.04 15.11 12H4.89C5.69 14.04 7.67 15.5 10 15.5Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default HappySmileyIcon;
