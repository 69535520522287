import { Center, Button, Skeleton, VStack, HStack } from '@chakra-ui/react';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useSubmitCheckout } from 'hooks/api/payment/submitCheckout';

export const CheckoutForm = () => {
  const { handleSubmit, stripe } = useSubmitCheckout();
  const [isLoading, setIsLoading] = useState(true);
  return (
    <form onSubmit={handleSubmit}>
      {isLoading && (
        <VStack align="left" h="390px" w="318px" spacing={6}>
          <HStack>
            <Skeleton w="90%" h="60px" />
            <Skeleton w="90%" h="60px" />
          </HStack>
          <Skeleton w="100%" h="50px" />
          <HStack>
            <Skeleton w="90%" h="50px" />
            <Skeleton w="90%" h="50px" />
          </HStack>
          <Skeleton w="100%" h="50px" />
        </VStack>
      )}
      <PaymentElement onReady={() => setIsLoading(false)} />
      <Center mt="6">
        <Button variant="primary" type="submit" disabled={!stripe}>
          Jetzt bestellen
        </Button>
      </Center>
    </form>
  );
};
