export const ButtonStyles = {
  baseStyle: {
    w: 'auto',
  },

  sizes: {
    xl: {
      fontSize: 'lg',
      px: '32px',
    },
    md: {
      fontSize: 'lg',
      px: '12px',
    },
    sm: {
      fontSize: 'lg',
      px: '12px',
    },
  },

  variants: {
    primary: {
      bg: 'brand.100',
      color: 'white',
      borderRadius: '30px',
      px: '10%',
      py: '2%',
      m: '2%',
    },
    secondary: {
      bg: 'none',
      color: 'brand.200',
      _hover: { background: 'none' },
      _active: { background: 'none' },
    },
    tertiary: {
      bg: 'none',
      color: 'brand.200',
      borderRadius: '30px',
      border: '3px solid',
      borderColor: 'brand.100',
      px: '20px',
      py: '20px',
    },
  },

  defaultProps: {},
};
