import Axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from 'config';
import storage from 'utils/storage';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();

  if (token && config.headers) {
    config.headers.authorization = `${token}`;
    config.headers.Accept = 'application/json';
  }
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

axios.interceptors.request.use(authRequestInterceptor);
