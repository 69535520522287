import {
  Box,
  Stack,
  Text,
  Spacer,
  useToast,
  Button,
  VStack,
  Heading,
  Flex,
} from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';
import { AutocompleteField, Form, SelectField } from 'components/Form';
import { ProgressBar } from 'components/welcome/ProgressBar';
import { useSchoolApi } from 'hooks/api/useSchoolsApi';

// assets
import { useUserApi } from 'hooks/api/useUserApi';
import { EducationStatus } from 'types/user';
import FtuLayout from 'layouts/FtuLayout';

const schema = Joi.object({
  name: Joi.string().required(),
  id: Joi.string().allow(null),
  educationStatus: Joi.string(),
});

type OrganizationInput = {
  name: string;
  id: string | null;
  educationStatus: EducationStatus;
};

export const Welcome: FunctionComponent = () => {
  const navigate = useNavigate();
  const { getAutoCompleteItems } = useSchoolApi();
  const { updateSchoolOfUser, updateFirstTimeUse } = useUserApi();
  const toast = useToast();
  const [isLoading, setLoading] = useState(false);
  return (
    <FtuLayout hasLogo>
      <Flex direction="column">
        <Box>
          <Stack color="brand.200" mt="10" spacing={4} px="5">
            <Heading size="md" fontWeight="bold">
              Willkommen
            </Heading>
            <Text align="left">
              Gib uns noch ein paar Infos, damit wir dein Lernerlebnis optimieren können.
            </Text>
          </Stack>
        </Box>
        <Box px="20px" mt="50px">
          <Form<OrganizationInput, typeof schema>
            onSubmit={async (values) => {
              setLoading(true);
              try {
                await updateFirstTimeUse();
                const user = await updateSchoolOfUser(
                  {
                    id: values.id,
                    name: values.name,
                  },
                  values.educationStatus
                );
                setLoading(false);
                if (user) {
                  navigate('/app/knowledge');
                }
              } catch (error) {
                setLoading(false);
                toast({
                  title: `Error! ${error}`,
                  status: 'error',
                });
              }
            }}
            schema={schema}
          >
            {({ register, formState, setValue, getValues }) => (
              <>
                <AutocompleteField
                  getAutocompleteItems={getAutoCompleteItems}
                  registrationName="name"
                  error={formState.errors.name}
                  idName="id"
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  label="Pflegeschule o. Institution"
                  placeholder="Neu anlegen oder auswählen"
                />
                <SelectField
                  options={[
                    {
                      label: '1. Lehrdrittel',
                      value: 'FIRST',
                    },
                    {
                      label: '2. Lehrdrittel',
                      value: 'SECOND',
                    },
                    {
                      label: '3. Lehrdrittel',
                      value: 'LAST',
                    },
                    {
                      label: 'Examiniert',
                      value: 'DONE',
                    },
                    {
                      label: 'Praxisanleitung',
                      value: 'GUIDE',
                    },
                    {
                      label: 'Lehrkraft',
                      value: 'TEACH',
                    },
                  ]}
                  placeholder="Bitte auswählen"
                  label="Ausbildungsstatus"
                  registration={register('educationStatus')}
                />
                <VStack h="100px" direction="column">
                  <Spacer />
                  <Button
                    variant="primary"
                    type="submit"
                    isLoading={isLoading}
                    loadingText="Speichern"
                  >
                    Mit dem Lernen beginnen
                  </Button>
                  <Spacer />
                </VStack>
              </>
            )}
          </Form>
        </Box>
        <Spacer />
        {/* <Box mt="110px">
          <ProgressBar
            steps={[
              { route: '/app/welcome', currentActive: true },
              { route: '/app/welcome/payment', currentActive: false },
            ]}
          />
        </Box> */}
      </Flex>
    </FtuLayout>
  );
};
