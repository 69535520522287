import { useToast, Text, VStack, Stack, Button, IconButton, useBoolean } from '@chakra-ui/react';
import Joi from 'joi';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Form, InputField } from 'components/Form';
import { useAuth } from 'lib/auth';
import { LoginCredentialsDto } from 'lib/dto/LoginCredentials.dto';
import AuthLayout from 'layouts/AuthLayout';
import { errorMessage } from 'components/Form/ErrorMessages';
import EyeIcon from 'assets/icons/eyeIcon';

const schema = Joi.object({
  email: Joi.string()
    .email({
      tlds: {
        allow: false,
      },
    })
    .required()
    .messages({
      'string.empty': errorMessage.empty,
      'string.email': errorMessage.email,
    }),
  password: Joi.string().required().messages({
    'string.empty': errorMessage.empty,
  }),
});

type LoginInputs = {
  email: string;
  password: string;
};
export const Login: FunctionComponent = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [flag, setFlag] = useBoolean();

  return (
    <AuthLayout>
      <Text color="brand.200">Dies ist eine Testversion für P-ELAN.</Text>
      <Stack spacing={6} align="left" width="100%" m={10} p={10}>
        <Form<LoginInputs, typeof schema>
          onSubmit={async (values: LoginCredentialsDto) => {
            try {
              const user = await login(values);
              const nextRoute = user?.isFirstTimeUse ? '/app/welcome' : '/app';
              navigate(nextRoute);
            } catch (error) {
              toast({
                title: errorMessage.toastLogin.ErrorTitle,
                description: errorMessage.toastLogin.ErrorDescription,
                status: 'error',
                duration: 10000,
              });
            }
          }}
          schema={schema}
        >
          {({ register, formState }) => (
            <>
              <InputField
                type="email"
                label="E-Mail-Adresse"
                error={formState.errors.email}
                registration={register('email')}
              />
              <InputField
                type={flag ? 'text' : 'password'}
                label="Passwort"
                error={formState.errors.password}
                registration={register('password')}
                rightElement={
                  <IconButton
                    aria-label="hide/show"
                    icon={<EyeIcon w="5" h="5" filled={flag} />}
                    onClick={setFlag.toggle}
                    variant="none"
                    color="teal"
                  >
                    {flag ? 'Hide' : 'Show'}
                  </IconButton>
                }
              />
              <Stack align="center">
                <Button variant="primary" type="submit">
                  Einloggen
                </Button>
              </Stack>
            </>
          )}
        </Form>
        <VStack spacing="6">
          <Link to="/auth/forgot-password">
            <Text color="brand.300" textDecoration="underline" fontWeight="bold">
              Passwort vergessen?
            </Text>
          </Link>
          {/* <Link to="/auth/register">
            Noch nicht dabei?
            <Text color="brand.300" textDecoration="underline" fontWeight="bold">
              Jetzt registrieren
            </Text>
          </Link> */}
        </VStack>
      </Stack>
    </AuthLayout>
  );
};
