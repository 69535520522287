import { useElements, useStripe } from '@stripe/react-stripe-js';
import { FormEvent } from 'react';

export const useSubmitCheckout = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) return null;

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/app/welcome/thank-you`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    }
  };

  return {
    handleSubmit,
    stripe,
  };
};
