import { Fragment } from 'react';
import { HStack, Box, Text } from '@chakra-ui/react';
import { Tag } from 'types/tag';

type TagBarProps = {
  tags: Tag[];
  px?: string;
  py?: string;
  pt?: string;
  fontWeight?: string;
  mt?: string;
  mb?: string;
};

const TagBar = (props: TagBarProps) => {
  const { tags, ...rest } = props;
  return (
    <HStack w="full" maxWidth="280px" wrap="wrap" {...rest}>
      {tags.map((tag: Tag, index: number) => (
        <Fragment key={tag.id}>
          <Text fontSize={tags.length > 4 ? '10px' : 'sm'} color="brand.600" fontWeight="500">
            {tag.icon ? tag.icon : null}
            {tag.name}
          </Text>

          {index !== tags.length - 1 && <Box w="1px" h="12px" bg="brand.500" />}
        </Fragment>
      ))}
    </HStack>
  );
};

export default TagBar;
