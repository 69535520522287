import { Box, Stack, Heading, Link, Text, Center, Button } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Joi from 'joi';
import { ProgressBar } from 'components/welcome/ProgressBar';
import { useUserApi } from 'hooks/api/useUserApi';
import FtuLayout from 'layouts/FtuLayout';
import { Form, InputField } from 'components/Form';
import { useUpdateUser } from 'hooks/api/user/addVoucher';
import { useAuth } from 'lib/auth';

const schema = Joi.object({
  voucher: Joi.string().required(),
});

type VoucherInput = {
  voucher: string;
};

export const Voucher = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { updateFirstTimeUse } = useUserApi();
  const addVoucher = useUpdateUser();
  return (
    <FtuLayout>
      <Box mt={2}>
        <Link verticalAlign="middle" onClick={() => navigate(-1)}>
          <ChevronLeftIcon w="6" h="5" />
          Zurück
        </Link>
        <Stack mt="10" spacing={4} px="5">
          <Heading textAlign="center" color="brand.200">
            Zahlung
          </Heading>
          <ProgressBar
            steps={[
              { route: '/app/welcome', currentActive: false },
              { route: '/app/welcome/payment', currentActive: true },
            ]}
          />
        </Stack>
        <Box px="20px" mt="100px">
          <Form<VoucherInput, typeof schema>
            onSubmit={async (values: { voucher: string }) => {
              await addVoucher.mutateAsync(values.voucher);
              await updateFirstTimeUse();
              if (user?.voucherId) navigate('/app');
            }}
            schema={schema}
          >
            {({ register, formState }) => (
              <>
                <InputField
                  type="text"
                  label="Voucher-Code"
                  error={formState.errors.voucher}
                  registration={register('voucher')}
                />
                <Text pt="15px">
                  Voucher-Codes bekommst du von teilnehmenden Schulen. Sie sind einmalig einlösbar
                  und haben eine beschränkte Laufzeit.
                </Text>
                <Box color="brand.300" pt="50px">
                  <Text align="center">Keinen Voucher-Code?</Text>
                  <Center>
                    <RouterLink to="/app/welcome/payment">Hier zur regulären Zahlung!</RouterLink>
                  </Center>
                </Box>
                <Stack pt="100px" spacing="6">
                  <Center>
                    <Button variant="primary" type="submit">
                      Weiter
                    </Button>
                  </Center>
                  <Center>
                    <Link
                      onClick={async () => {
                        await updateFirstTimeUse();
                        navigate('/app');
                      }}
                    >
                      Free version testen
                    </Link>
                  </Center>
                </Stack>
              </>
            )}
          </Form>
        </Box>
      </Box>
    </FtuLayout>
  );
};
