import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { ReactNode } from 'react';
import { useForm, UseFormReturn, SubmitHandler, UseFormProps } from 'react-hook-form';
import { Stack } from '@chakra-ui/react';

type FormProps<TFormValues, Schema> = {
  className?: string;
  onSubmit: SubmitHandler<TFormValues>;
  children: (methods: UseFormReturn<TFormValues>) => ReactNode;
  options?: UseFormProps<TFormValues>;
  id?: string;
  schema?: Schema;
};

export const Form = <
  TFormValues extends Record<string, unknown> = Record<string, unknown>,
  Schema extends Joi.ObjectSchema = Joi.ObjectSchema
>({
  onSubmit,
  children,
  className,
  options,
  id,
  schema,
}: FormProps<TFormValues, Schema>) => {
  const methods = useForm<TFormValues>({
    ...options,
    resolver: schema && joiResolver(schema),
  });

  return (
    <form className={className} onSubmit={methods.handleSubmit(onSubmit)} id={id}>
      <Stack spacing={3}>{children(methods)}</Stack>
    </form>
  );
};
