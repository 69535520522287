import { axios } from 'lib/axios';
import { useMutation } from 'react-query';
import { queryClient } from 'lib/react-query';

export const addLikedArticle = async (id: string): Promise<void> => {
  await axios.patch(`user/update/add-article/${id}`);
};

export const useAddLikedArticle = () => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('liked-articles');
    },
    mutationFn: addLikedArticle,
  });
};
