import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router';
import { axios } from 'lib/axios';
import { ResetPasswordDto } from './dto/resetPassword.dto';

export const useAuthApi = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const resendVerification = async (): Promise<void> => {
    try {
      const resp = await axios.post('/auth/resend-confirmation-link');
      if (resp.status === 201) {
        toast({
          title: 'Email versendet',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Upps',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const sendResetPasswordMail = async (values: { email: string }): Promise<void> => {
    try {
      const response: AxiosResponse = await axios.post('/auth/forgot-password', values);

      if (response.status === 201) {
        toast({
          title: 'Email versendet',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Upps',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const resetPassword = async (values: ResetPasswordDto) => {
    try {
      if (!values.token) {
        toast({
          title: 'Kein Token!',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
      const response = await axios.post('/auth/reset-password', {
        password: values.password,
        token: values.token,
      });

      if (response.status === 201) {
        setTimeout(() => {
          navigate('/auth/login');
        }, 2000);
        toast({
          title: 'Passwort geändert',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Upps',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return { resendVerification, sendResetPasswordMail, resetPassword };
};
