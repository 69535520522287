import { useQuery } from 'react-query';
import { axios } from 'lib/axios';
import { Article } from 'types/content';

export const getArticles = async (): Promise<Article[]> => {
  const a = await axios.get(`/article/liked-articles`);
  return a.data;
};

export const useLikedArticles = () => {
  return useQuery({
    queryKey: ['liked-articles'],
    queryFn: () => getArticles(),
  });
};
