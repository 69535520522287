import { HStack, Circle } from '@chakra-ui/react';

type NavigationStep = {
  route: string;
  currentActive: boolean;
};

type ProgessBarProps = {
  steps: NavigationStep[];
};

// TODO: prototype layout with divider lines

export const ProgressBar = ({ steps }: ProgessBarProps) => {
  return (
    <HStack justify="center" mt="5">
      {steps.map((step) => (
        <Circle
          key={step.route}
          size="13px"
          bg={step.currentActive ? 'brand.300' : ''}
          border="2px"
          borderColor="brand.300"
        />
      ))}
    </HStack>
  );
};
