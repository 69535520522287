// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { AnalyticsCallOptions, getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logFirebaseEvent = (
  message: string,
  eventParams?: { [key: string]: any },
  options?: AnalyticsCallOptions
) => {
  logEvent(analytics, message, eventParams, options);
};
