import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  VStack,
} from '@chakra-ui/react';
import parse, { Element, Text } from 'html-react-parser';
import { Fragment } from 'react';
import { ContentPiece, Keyword } from '../../types/content';
import { ArticleImage } from './ArticleImage';
import { Keyword as KeywordComp } from './Keyword';

type HTMLParserProps = {
  content: ContentPiece[];
  keywords: Keyword[];
};

// Method to get content from a nested structure (keyword in keyword)
const getContentFromDomNode = (domNode: Element) => {
  const content: string[] = [];

  const contentRecursion = (node: any) => {
    node.children.forEach((child: any) => {
      if (child.type === 'text') {
        content.push(child.data.trim());
      }
      // if the type is tag we have to go deeper the tree
      if (child.type === 'tag') {
        contentRecursion(child);
      }
    });
  };

  contentRecursion(domNode);

  // add question mark without whitespace
  if (content[content.length - 1] === '?') {
    content.pop();
    return `${content.join(' ')}?`;
  }

  return content.join(' ');
};

const parser = (input: string, keywords?: Keyword[]) => {
  return parse(input, {
    replace: (domNode) => {
      // get all pre tagged keywords
      if (domNode instanceof Element && domNode.attribs.class === 'c-keyword') {
        if (domNode.children[0].type === 'text' && domNode.children.length === 1) {
          const content = domNode.children[0] as Text;
          return <KeywordComp name={content.data} />;
        }
        const content = getContentFromDomNode(domNode);
        return <KeywordComp name={content} />;
      }

      // find keywords that were not pretagged with the class
      if (
        domNode instanceof Element &&
        domNode.tagName === 'span' &&
        domNode.attribs.class !== 'c-keyword'
      ) {
        const keyword = keywords?.filter((word) => {
          return word.name === (domNode.children[0] as Text).data;
        });
        if (keyword && keyword?.length > 0) {
          return <KeywordComp name={keyword[0].name} />;
        }
      }
      if (
        domNode instanceof Element &&
        domNode.name === 'img' &&
        domNode.attribs.src &&
        domNode.attribs.src !==
          'https://keelearningdiag.blob.core.windows.net/laravel-file-storage-prod/uploads/emoji-nova-5RvddxSqpjkP0apRgCAl8KnKB5E5Ha4VnghsqWUA.png'
      ) {
        return <ArticleImage imgUrl={domNode.attribs.src} />;
      }
    },
  });
};

const titleParser = (input: string) => {
  return parse(input, {});
};

export const HTMLParser = ({ content, keywords }: HTMLParserProps) => {
  return (
    <Box px="5" py="15">
      {content.map((singleContentPiece: ContentPiece) => (
        <Fragment key={singleContentPiece.id}>
          {singleContentPiece.type === 'DROPDOWN' &&
            (singleContentPiece.position === 0 ? (
              <VStack px={4} align="left">
                <Heading>{titleParser(singleContentPiece.title)}</Heading>
                {parser(singleContentPiece.content)}
              </VStack>
            ) : (
              <Accordion allowToggle>
                <AccordionItem>
                  <AccordionButton
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Heading color="brand.200" textAlign="left">
                      {titleParser(singleContentPiece.title.replace('color: #43b188;', ''))}
                    </Heading>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel px={8}>
                    {' '}
                    {parser(singleContentPiece.content, keywords)}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            ))}
          {singleContentPiece.type === 'TEXT' && (
            <Box px={6} py={6}>
              {parser(singleContentPiece.content, keywords)}
            </Box>
          )}
        </Fragment>
      ))}
    </Box>
  );
};
