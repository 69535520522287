import { useQuery } from 'react-query';
import { axios } from 'lib/axios';
import { Article } from 'types/content';
import { useParams } from 'react-router';

export const getArticles = async (id: string | undefined): Promise<Article> => {
  const article = await axios.get(`/article/${id}`);
  return article.data[0];
};

export const useArticle = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [`article-${id}`],
    queryFn: () => getArticles(id),
    enabled: !!id,
    refetchOnReconnect: 'always',
    cacheTime: 1,
  });
};
