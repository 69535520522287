import { MouseEventHandler } from 'react';
import { Box, Text } from '@chakra-ui/react';

type FolderBoxProps = {
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
  name: string;
};

export const FolderBox = ({ onClick, name }: FolderBoxProps) => {
  return (
    <Box
      p="4"
      onClick={onClick}
      boxShadow="md"
      borderRadius="xl"
      height="60px"
      bg="brand.50"
      display="flex"
      minWidth="180px"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
    >
      <Text color="brand.200" fontWeight="600" textAlign="center" wordBreak="keep-all" isTruncated>
        {name}
      </Text>
    </Box>
  );
};
