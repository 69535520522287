import { useQuery } from 'react-query';
import { axios } from 'lib/axios';
import { Folder } from 'types/content';

export const getFolderById = async (id: string | null): Promise<Folder[]> => {
  const content = await axios.get(`/folder/by-parent-name/article/${id}`);
  return content.data;
};

export const useFolders = (id: string | null) => {
  return useQuery({
    queryKey: [`folder-${id}`],
    queryFn: () => getFolderById(id),
  });
};
