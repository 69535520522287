import { useRoutes } from 'react-router';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'lib/auth';
import { Landing } from 'pages/landing';
import { Welcome } from 'pages/welcome';
import { Payment } from 'pages/welcome/payment';
import { Voucher } from 'pages/welcome/voucher';
import { PaymentMethod } from 'pages/welcome/payment-method';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { ThankYou } from '../pages/welcome/danke';

export const AppRoutes = () => {
  const auth = useAuth();
  const commonRoutes = [{ path: '/', element: <Landing /> }];

  const firstTimeRoutes =
    auth.user && auth.user.isFirstTimeUse
      ? [
          {
            path: '/app/welcome',
            element: <Welcome />,
          },
          {
            path: '/app/welcome/payment',
            element: <Payment />,
          },
          {
            path: '/app/welcome/payment-method',
            element: <PaymentMethod />,
          },
          {
            path: '/app/welcome/thank-you',
            element: <ThankYou />,
          },
          {
            path: '/app/welcome/voucher',
            element: <Voucher />,
          },
        ]
      : [
          {
            path: '/app/welcome',
            element: <Navigate to="/auth/login" />,
          },
        ];

  const element = useRoutes([
    ...protectedRoutes,
    ...publicRoutes,
    ...commonRoutes,
    ...firstTimeRoutes,
  ]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{element}</>;
};
