import { VStack, HStack, Heading, Box, Text, Spacer, Button, Skeleton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Article, Folder, ParentFolder } from 'types/content';
import { queryClient } from 'lib/react-query';
import { ContentCard } from 'components/Content/ContentCard';
import { useArticlesFromAFolder } from 'hooks/api/content/article/getArticlesFromAFolder';
import { isLiked } from 'utils/isLiked';
import { useLikedArticles } from 'hooks/api/content/article/getLikedArticles';
import { getFolderById } from '../../hooks/api/content/folder/getFolderById';
import { FolderBox } from './FolderBox';

type CardRowProps = {
  id: string;
  title?: string;
  subFolders?: Folder[] | ParentFolder[];
  folder?: Folder;
};

export const CardRow = ({ id, title, subFolders = [], folder }: CardRowProps) => {
  const navigate = useNavigate();
  const articleQuery = useArticlesFromAFolder(id);

  const likedArticlesQuery = useLikedArticles();
  if (articleQuery.data?.length === 0 && subFolders.length === 0) return null;
  return (
    <VStack align="left" spacing={5}>
      <HStack>
        <Heading size="md" color="brand.200" maxWidth="55%">
          {title}
        </Heading>
        {folder && (
          <>
            <Spacer />
            <Button
              variant="secondary"
              mr="2"
              textDecoration="underline"
              size="sm"
              fontSize="sm"
              color="brand.300"
              onClick={async () => {
                await queryClient.prefetchQuery(`folder-${folder.id}`, () =>
                  getFolderById(folder.id)
                );
                navigate(`/app/knowledge/folder/${folder.id}`);
              }}
            >
              Alles zum Thema
            </Button>
          </>
        )}
      </HStack>
      <HStack
        overflowX="scroll"
        alignItems="stretch"
        pl="5px"
        mr="0px"
        pr="0px"
        pb="2px"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&::-webkit-scrollbar-track': {
            display: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            display: 'none',
          },
        }}
      >
        {subFolders.length > 0 &&
          subFolders.map((subFolder: Folder | ParentFolder) => {
            return (
              <FolderBox
                key={subFolder.id}
                name={subFolder.name}
                onClick={async () => {
                  await queryClient.prefetchQuery(`folder-${subFolder.id}`, () =>
                    getFolderById(subFolder.id)
                  );
                  navigate(`/app/knowledge/folder/${subFolder.id}`);
                }}
              />
            );
          })}
      </HStack>
      <HStack
        maxWidth="100vw"
        overflowX="scroll"
        pl="5px"
        mr="0px"
        pr="0px"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&::-webkit-scrollbar-track': {
            display: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            display: 'none',
          },
        }}
      >
        {articleQuery.isLoading && (
          <>
            <Skeleton
              height="190px"
              minWidth="280px"
              maxWidth="280px"
              borderRadius="15px"
              startColor="brand.700"
              endColor="brand.50"
            />
            <Skeleton
              height="190px"
              minWidth="280px"
              maxWidth="280px"
              borderRadius="15px"
              startColor="brand.700"
              endColor="brand.50"
            />
          </>
        )}
        {articleQuery.data &&
          articleQuery.data.map((article: Article) => {
            return (
              <ContentCard
                id={article.id}
                parentFolders={article.parentFolders}
                title={article.title}
                link={`/app/knowledge/${article.id}`}
                key={article.id}
                keywords={article.keywords}
                isLiked={isLiked(likedArticlesQuery.data, article.id)}
                readingTime={article.readingTime}
                educationStatus={article.educationStatus}
              />
            );
          })}
      </HStack>
    </VStack>
  );
};
