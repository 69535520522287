import { Icon, IconProps } from '@chakra-ui/react';

const ShareIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 16 20" {...props}>
      <path
        d="M8 6.57617C8.37793 6.57617 8.69434 6.25977 8.69434 5.89062V3.33301L8.6416 2.01465L9.23047 2.63867L10.5664 4.0625C10.6895 4.20312 10.874 4.27344 11.041 4.27344C11.4014 4.27344 11.6826 4.00977 11.6826 3.64941C11.6826 3.46484 11.6035 3.32422 11.4717 3.19238L8.50098 0.327148C8.3252 0.151367 8.17578 0.0898438 8 0.0898438C7.81543 0.0898438 7.66602 0.151367 7.49023 0.327148L4.51953 3.19238C4.3877 3.32422 4.30859 3.46484 4.30859 3.64941C4.30859 4.00977 4.57227 4.27344 4.94141 4.27344C5.1084 4.27344 5.30176 4.20312 5.4248 4.0625L6.76074 2.63867L7.34961 2.01465L7.29688 3.33301V5.89062C7.29688 6.25977 7.62207 6.57617 8 6.57617ZM2.95508 19.9268H13.0361C14.873 19.9268 15.7959 19.0127 15.7959 17.2021V8.60645C15.7959 6.7959 14.873 5.88184 13.0361 5.88184H8.89648V12.3594C8.89648 12.8428 8.4834 13.2383 8 13.2383C7.50781 13.2383 7.09473 12.8428 7.09473 12.3594V5.88184H2.95508C1.11816 5.88184 0.195312 6.7959 0.195312 8.60645V17.2021C0.195312 19.0127 1.11816 19.9268 2.95508 19.9268Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default ShareIcon;
