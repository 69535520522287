import { useQuery } from 'react-query';
import { axios } from 'lib/axios';
import { Keyword } from 'types/content';

export const getKeyword = async (name: string): Promise<Keyword> => {
  const keyword = await axios.post(`/keyword/name`, { name });
  return keyword.data;
};

export const useKeyword = (name: string) => {
  return useQuery({
    queryKey: [`keyword-${name}`],
    queryFn: () => getKeyword(name),
    enabled: !!name,
  });
};
