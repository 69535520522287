import { Flex, IconButton, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { ReactElement } from 'react';
import KnowledgeIcon from 'assets/icons/knowledgeIcon';
import HeartIcon from 'assets/icons/heartIcon';

type NavigationBarProps = {
  label: string;
  to: string;
  className?: string;
  ariaLabel: string;
  icon: ReactElement;
};

// TODO: connect colors to theme (currently not working, thus hardcoded) -> alternative way?
const NavigationLink = (props: NavigationBarProps) => {
  const { to, className, ariaLabel, icon, label } = props;
  return (
    <NavLink
      className={className}
      style={({ isActive }) => (isActive ? { color: '#2B3569' } : { color: '#9C9FB0' })}
      to={to}
    >
      <IconButton aria-label={ariaLabel} icon={icon} />
      <Text fontSize="11px">{label}</Text>
    </NavLink>
  );
};

export const NavigationBar = () => {
  return (
    <Flex
      position="fixed"
      fontSize={14}
      textAlign="center"
      bottom={0}
      w="100%"
      h={16}
      px={8}
      pt={8}
      pb={10}
      alignItems="center"
      justifyContent="space-around"
      bg="brand.700"
      boxShadow="0px -2px 0px rgba(190, 196, 204, 0.4)"
      borderTop="100px"
      borderColor="green.200"
    >
      <NavigationLink
        to="/app/knowledge"
        label="Wissen"
        ariaLabel=""
        icon={<KnowledgeIcon w="6" h="6" pb="0" />}
      />

      <NavigationLink
        to="/app/favorites"
        label="Favoriten"
        ariaLabel=""
        icon={<HeartIcon w="6" h="6" pb="0" checked />}
      />
    </Flex>
  );
};
