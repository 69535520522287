import { Icon, IconProps } from '@chakra-ui/react';

interface EypeProps extends IconProps {
  filled: boolean;
  w: string;
  h: string;
}

const EyeIcon = (props: EypeProps) => {
  const { filled } = props;
  return (
    <Icon viewBox="0 0 22 16" {...props}>
      {filled ? (
        <path
          d="M11 5C10.2044 5 9.44129 5.31607 8.87868 5.87868C8.31607 6.44129 8 7.20435 8 8C8 8.79565 8.31607 9.55871 8.87868 10.1213C9.44129 10.6839 10.2044 11 11 11C11.7956 11 12.5587 10.6839 13.1213 10.1213C13.6839 9.55871 14 8.79565 14 8C14 7.20435 13.6839 6.44129 13.1213 5.87868C12.5587 5.31607 11.7956 5 11 5ZM11 13C9.67392 13 8.40215 12.4732 7.46447 11.5355C6.52678 10.5979 6 9.32608 6 8C6 6.67392 6.52678 5.40215 7.46447 4.46447C8.40215 3.52678 9.67392 3 11 3C12.3261 3 13.5979 3.52678 14.5355 4.46447C15.4732 5.40215 16 6.67392 16 8C16 9.32608 15.4732 10.5979 14.5355 11.5355C13.5979 12.4732 12.3261 13 11 13ZM11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5Z"
          fill="currentColor"
        />
      ) : (
        <path
          fill="currentColor"
          d="M11.5,3C10,3,8.6,3.5,7.7,4.6C6.6,5.6,6,7,6,8.5s0.6,2.9,1.7,3.9C8.6,13.4,10,14,11.5,14s2.9-0.6,3.9-1.7
	C16.5,11.4,17,10,17,8.5s-0.6-2.9-1.7-3.9S13,3,11.5,3z M11.8,13c-1.1,0-2.2-0.4-3-1.3c-0.8-0.8-1.3-1.9-1.3-3s0.4-2.2,1.3-3
	c0.8-0.8,1.9-1.3,3-1.3s2.2,0.4,3,1.3c0.8,0.8,1.3,1.9,1.3,3s-0.4,2.2-1.3,3C14,12.6,12.9,13,11.8,13z M11,0.5C6,0.5,1.7,3.6,0,8
	c1.7,4.4,6,7.5,11,7.5s9.3-3.1,11-7.5C20.3,3.6,16,0.5,11,0.5z"
        />
      )}
    </Icon>
  );
};

export default EyeIcon;
