import { Icon, IconProps } from '@chakra-ui/react';

const EduIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 17 15" {...props}>
      <path
        d="M15.7871 3.50977L10.0625 0.890625C9.44727 0.609375 8.94336 0.480469 8.45117 0.492188C7.95898 0.480469 7.45508 0.609375 6.83984 0.890625L1.11523 3.50977C0.705078 3.69141 0.494141 4.03125 0.494141 4.37695C0.494141 4.72852 0.705078 5.0625 1.11523 5.24414L3.38281 6.26953L7.18555 4.49414C7.16211 4.45312 7.15039 4.41797 7.15039 4.37695C7.15039 3.94922 7.80078 3.64453 8.46875 3.64453C9.14258 3.64453 9.78711 3.94922 9.78711 4.37695C9.78711 4.81641 9.14258 5.12695 8.46875 5.12695C8.26367 5.12695 8.05273 5.09766 7.86523 5.03906L4.29688 6.70312L6.83984 7.86914C7.45508 8.15039 7.95898 8.27344 8.45117 8.26758C8.94336 8.27344 9.44727 8.15039 10.0625 7.86914L15.7871 5.24414C16.1973 5.05664 16.4082 4.72852 16.4082 4.37695C16.4082 4.03125 16.1973 3.69141 15.7871 3.50977ZM4.27344 7.64062V11.0684C5.29297 11.6777 6.74023 12.0469 8.45117 12.0469C11.8906 12.0469 14.252 10.5645 14.252 8.73633V6.89062L10.4199 8.64844C9.71094 8.9707 9.06641 9.13477 8.45117 9.12891C7.8418 9.13477 7.19141 8.9707 6.48242 8.64844L4.27344 7.64062ZM2.65039 6.89648V8.73633C2.65039 9.24609 2.91992 9.84961 3.40625 10.3652V7.24219L2.65039 6.89648ZM2.89062 12.457V13.9746C2.89062 14.4727 3.21875 14.8008 3.7168 14.8008H3.96289C4.46094 14.8008 4.7832 14.4727 4.7832 13.9746V12.457C4.7832 12.0703 4.58984 11.7891 4.27344 11.6777V11.0684C3.93359 10.8633 3.64062 10.6289 3.40625 10.3652V11.6777C3.08984 11.7832 2.89062 12.0703 2.89062 12.457Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default EduIcon;
