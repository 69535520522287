import { Icon, IconProps } from '@chakra-ui/react';

const YearsIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 19 15" {...props}>
      <path
        d="M9.45231 0L0.285645 5L3.61898 6.81667V11.8167L9.45231 15L15.2856 11.8167V6.81667L16.9523 5.90833V11.6667H18.619V5L9.45231 0ZM15.1356 5L9.45231 8.1L3.76898 5L9.45231 1.9L15.1356 5ZM13.619 10.8333L9.45231 13.1L5.28564 10.8333V7.725L9.45231 10L13.619 7.725V10.8333Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default YearsIcon;
