import { axios } from 'lib/axios';
import { useMutation } from 'react-query';
import { queryClient } from 'lib/react-query';

export const removeLikedArticle = async (id: string): Promise<void> => {
  await axios.delete(`user/remove-article/${id}`);
};

export const useRemoveLikedArticle = () => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('liked-articles');
    },
    mutationFn: removeLikedArticle,
  });
};
