import { Checkbox, Link } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type Checkboxprops = FieldWrapperPassThroughProps & {
  type?: 'boolean';
  className?: string;
  placeholder?: string;
  registration: Partial<UseFormRegisterReturn>;
  description: string;
  link: string;
  linkTitle: string;
};

export const CheckboxField = (props: Checkboxprops) => {
  const { type, className, registration, error, placeholder, description, link, linkTitle } = props;
  return (
    <FieldWrapper error={error}>
      <Checkbox
        type={type}
        className={className}
        colorScheme="purple"
        {...registration}
        placeholder={placeholder}
        spacing=".8rem"
        size="md"
        css={css`
          > .chakra-checkbox__control {
            border: 2px solid #845da5;
          }
        `}
      >
        {description}
        <Link href={link} textDecoration="underline" ml="5px">
          {linkTitle}
        </Link>
      </Checkbox>
    </FieldWrapper>
  );
};
