/* eslint-disable react-hooks/exhaustive-deps */
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import {
  Flex,
  VStack,
  Badge,
  Box,
  Text,
  Heading,
  useClipboard,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { ContentPiece, Keyword } from 'types/content';
import TagBar from 'components/Content/TagBar';
import { BackButton } from 'components/General/BackButton';
import { useUpdateLastViewedArticle } from 'hooks/api/user/updateLastViewedArticle';
import { useAddLikedArticle } from 'hooks/api/user/updateLikedArticles';
import { useRemoveLikedArticle } from 'hooks/api/user/removeLikedArticle';
import { isLiked } from 'utils/isLiked';
import { NavigationBar } from 'components/General/NavigationBar';
import ClockIcon from 'assets/icons/ClockIcon';
import EduIcon from 'assets/icons/EduIcon';
import TextIcon from 'assets/icons/TextIcon';
import { ButtonBar } from 'components/Article/ButtonBar';
// import { SwitchButton } from 'components/Article/Switch';
import { HTMLParser } from 'components/Article/HtmlParser';
import { FeedbackForm } from 'components/Article/FeedbackForm';
import { ShareScreen } from 'components/Article/ShareScreen';
import { CardRow } from 'components/Content/CardRow';
import { useArticle } from 'hooks/api/content/article/getContentById';
import { useLikedArticles } from 'hooks/api/content/article/getLikedArticles';
import { logFirebaseEvent } from 'lib/firebaseConfig';
import { NotFound } from 'pages/landing/notFound';
import { getEducationStatus } from '../../../components/Content/ContentCard';

const ArticlePage = () => {
  // data fetching
  const { id } = useParams();
  // const navigate = useNavigate();
  const { isLoading, isError, data: article } = useArticle();
  const updateLikedArticlesMutation = useAddLikedArticle();
  const removeLikedArticleMutation = useRemoveLikedArticle();
  const likedArticlesQuery = useLikedArticles();
  const updateLastViewedArticle = useUpdateLastViewedArticle();

  // copy action
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { onCopy } = useClipboard(window.location.href);

  // ui helpers
  const titleRef = useRef<HTMLHeadingElement>(null);
  const titleHeight = titleRef.current ? titleRef.current.clientHeight : 100;
  const top = useCallback((node) => {
    if (node !== null) {
      node.scrollTo(0, 0);
    }
  }, []);
  const { isOpen, onToggle } = useDisclosure();
  // const [showFullText, setShowFullText] = useState<boolean>(true);

  // lifecycle hooks
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        await updateLastViewedArticle.mutateAsync(id);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error</span>;
  }

  if (!article) {
    return (
      <NotFound
        title="Dieser Artikel kann nicht gefunden werden"
        buttonText="Zurück"
        buttonLink="/app/knowledge"
      />
    );
  }

  if (!likedArticlesQuery.data) return null;

  const onCardKeywords: Keyword[] = article.keywords.filter(
    (keyword: Keyword) => keyword.keywordType === 'ONCARD'
  );

  const inTextKeywords: Keyword[] = article.keywords.filter(
    (keyword: Keyword) => keyword.keywordType === 'INTEXT'
  );

  return (
    <VStack w="100vw" align="left" spacing="0px" maxW="100vw">
      <Box pos="fixed" left="50%" zIndex="100" top="0" transform="translateX(-50%)">
        <Badge colorScheme="purple">Forschungsversion zur Studie P-ELAN</Badge>
      </Box>
      <Box
        ref={top}
        w="100vw"
        h="100vh"
        bgGradient="radial-gradient(164% 62.44% at -33.33% 6.53%, rgba(172, 183, 243, 0.3) 0%, rgba(172, 183, 243, 0) 100%), radial-gradient(57.7% 57.7% at 109.47% -9.61%, rgba(229, 105, 174, 0.3) 0%, rgba(229, 105, 174, 0) 100%), #FFFFFF;"
      >
        <Box mt="4" ml="2" pl="5" position="relative">
          <BackButton />
        </Box>
        <Flex mx="5" mt="10" align="left" flexDirection="column">
          <Text fontSize="md" fontWeight="600" color="brand.200">
            {article.parentFolders && article.parentFolders.length > 0
              ? article.parentFolders[0].name
              : 'Unbekannt'}
          </Text>
          <Heading size="lg" color="brand.100" mb="4" ref={titleRef}>
            {article && article.title}
          </Heading>
        </Flex>
      </Box>
      <VStack
        position="absolute"
        w="100vw"
        bg="brand.50"
        top={`${titleHeight + 150}px`}
        align="left"
        pb="15vh"
      >
        <Box px="8">
          <VStack align="left" spacing="1" pt="4" pb="2">
            <TagBar
              tags={[
                {
                  id: `${article.readingTime} min.`,
                  name: article.readingTime ? `${article.readingTime} min.` : `${0} min.`,
                  icon: <ClockIcon mr="5px" mb="1px" />,
                },
                {
                  id: 'alsdkfj',
                  name: getEducationStatus(article.educationStatus),
                  icon: <EduIcon mr="5px" mb="1px" />,
                },
              ]}
            />
            <TagBar tags={[...onCardKeywords]} />
          </VStack>
          <Box>
            <Divider />
          </Box>
        </Box>
        <ButtonBar
          onShare={() => {
            logFirebaseEvent('share', { content: article && article.title });
            onToggle();
          }}
          fileUrl={null}
          onLike={() =>
            isLiked(likedArticlesQuery.data, article.id)
              ? removeLikedArticleMutation.mutateAsync(article.id)
              : updateLikedArticlesMutation.mutateAsync(article.id)
          }
          isLiked={isLiked(likedArticlesQuery.data, article.id)}
        />
        {/* Will be added in the future */}
        {/* <SwitchButton
          showFullText={showFullText}
          onLeftClick={() => setShowFullText(true)}
          onRightClick={() => setShowFullText(false)}
        /> */}
        <HTMLParser
          content={article && article.content ? article.content : ([] as ContentPiece[])}
          keywords={inTextKeywords}
        />
        <Box px="8" pb="8">
          <FeedbackForm articleId={article.id} />
        </Box>

        <Box pl="10">
          <CardRow
            id={article.parentFolders[0].id}
            title="Aus der Kategorie"
            subFolders={article.parentFolders}
          />
        </Box>
      </VStack>

      <NavigationBar />
      <ShareScreen
        isOpen={isOpen}
        onToggle={() => {
          onToggle();
        }}
        articleTitle={article.title}
        onLike={() => {
          onCopy();
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 2000);
        }}
        isCopied={isCopied}
      />
    </VStack>
  );
};

export default ArticlePage;
