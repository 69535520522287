import { useQuery } from 'react-query';
import { axios } from 'lib/axios';
import { Article } from 'types/content';
import { useStore } from 'store';

export const getArticlesFromAFolder = async (
  id: string | null,
  filters: Array<string>
): Promise<Article[]> => {
  const filtersForBackend: Array<{ id: string }> = [];

  filters.forEach((filter) => {
    filtersForBackend.push({ id: filter });
  });

  if (id) {
    const content = await axios.post(`/article/by-parent-name`, {
      id,
      keywordFilter: [...filtersForBackend],
    });
    return content.data;
  }

  return [];
};

export const useArticlesFromAFolder = (id: string | null) => {
  const { activeFilters } = useStore();
  return useQuery({
    queryKey: [`articles-from-folder-${id}`, activeFilters],
    queryFn: () => getArticlesFromAFolder(id, activeFilters),
    enabled: !!id,
  });
};
