/* eslint-disable camelcase */
import { Heading, Button, VStack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { SlideOver } from 'components/Settings/SlideOver';
import FtuLayout from 'layouts/FtuLayout';
import { useUserApi } from 'hooks/api/useUserApi';

export const ThankYou = () => {
  const navigate = useNavigate();
  const { updateFirstTimeUse } = useUserApi();

  const goTo = (path: string) => {
    return async () => {
      await updateFirstTimeUse();
      navigate(path);
    };
  };

  return (
    <FtuLayout>
      <SlideOver minHeight="100vh">
        <VStack mx="6%" textAlign="center" spacing={10}>
          <Heading>Vielen Dank!</Heading>
          <Text>
            Du unterstützt mit deinem Abbonnement das Novaheal-Team und die digitalisierung der
            Pflege.
          </Text>

          <Button variant="primary" onClick={goTo('/app/knowledge')}>
            Jetzt lernen
          </Button>
        </VStack>
      </SlideOver>
    </FtuLayout>
  );
};
