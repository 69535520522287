import { axios } from 'lib/axios';
import { useMutation } from 'react-query';
import { queryClient } from 'lib/react-query';

export const addLikedArticle = async (id: string): Promise<void> => {
  await axios.patch(`article/add-view/${id}`);
};

export const useUpdateLastViewedArticle = () => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('last-viewed-articles');
    },
    mutationFn: addLikedArticle,
  });
};
