import { Box, Heading, VStack, Text, Button, Image } from '@chakra-ui/react';
import NotFoundImage from 'assets/java-not-found.png';
import { useNavigate } from 'react-router';

type NotFoundProps = {
  title?: string;
  buttonText?: string;
  buttonLink?: string;
};

export const NotFound = ({
  title = 'Upps, diese Seite gibt es nicht.',
  buttonText = 'Zum Login',
  buttonLink = '/auth/login',
}: NotFoundProps) => {
  const navigate = useNavigate();
  return (
    <VStack
      w="100wv"
      h="100vh"
      bg="linear-gradient(90deg, rgba(236,239,252,1) 0%, rgba(244,221,238,1) 84%)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      px="10"
      textAlign="center"
      spacing="10"
    >
      <Image src={NotFoundImage} />
      <Box>
        <Heading size="md" color="brand.200">
          {title}
        </Heading>
        <Text color="brand.500" mt="4">
          Aber sei nicht traurig. Wir haben hier ein süßes Welpenbild von unserem Bürohund Java. ;-)
        </Text>
      </Box>
      <Button variant="primary" onClick={() => navigate(buttonLink)}>
        {buttonText}
      </Button>
    </VStack>
  );
};
