import { Box, Link, Stack, Text, HStack } from '@chakra-ui/react';
// TODO: Insertion of link paths

export const Footer = () => (
  <Box
    as="footer"
    backgroundColor="brand.400"
    color="brand.200"
    mx="0px"
    maxW="7xl"
    w="full"
    py="6"
    px={{ base: '24', md: '80' }}
    pos="fixed"
    bottom="0"
    fontWeight="700"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <HStack divider={<Text>|</Text>}>
      <Link href="https://www.novaheal.de/app-datenschutz" pr="10px">
        Datenschutz
      </Link>
      <Link href="https://www.novaheal.de/app-impressum" pl="10px">
        Impressum
      </Link>
    </HStack>
  </Box>
);
