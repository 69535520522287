import { Icon, IconProps } from '@chakra-ui/react';

const TwitterIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 16 20" {...props}>
      <path
        fill="currentColor"
        d="M 5 3 C 3.897 3 3 3.897 3 5 L 3 19 C 3 20.103 3.897 21 5 21 L 19 21 C 20.103 21 21 20.103 21 19 L 21 5 C 21 3.897 20.103 3 19 3 L 5 3 z M 5 5 L 19 5 L 19.001953 19 L 5 19 L 5 5 z M 14.566406 7.1132812 C 13.194406 7.1132812 12.080078 8.2286094 12.080078 9.5996094 C 12.080078 9.8566094 12.166016 10.028219 12.166016 10.199219 C 10.109016 10.114219 8.309375 9.0859063 7.109375 7.6289062 C 6.852375 7.9719062 6.765625 8.399125 6.765625 8.828125 C 6.765625 9.685125 7.1948594 10.372656 7.8808594 10.972656 C 7.4528594 10.886656 7.108625 10.799906 6.765625 10.628906 C 6.765625 11.828906 7.6223281 12.772297 8.7363281 13.029297 C 8.4793281 13.115297 8.3077812 13.115234 8.0507812 13.115234 C 7.9647813 13.115234 7.7920938 13.029297 7.6210938 13.029297 C 7.9640937 13.972297 8.8215469 14.742188 9.9355469 14.742188 C 9.0785469 15.342187 7.9636094 15.771484 6.8496094 15.771484 L 6.25 15.771484 C 7.364 16.456484 8.6504844 16.886719 10.021484 16.886719 C 14.564484 16.886719 17.050781 13.114422 17.050781 9.8574219 L 17.050781 9.5136719 C 17.479781 9.1706719 17.906953 8.7425625 18.251953 8.2265625 C 17.737953 8.4845625 17.308922 8.57025 16.794922 8.65625 C 17.308922 8.31425 17.737203 7.8851562 17.908203 7.2851562 C 17.479203 7.5421562 16.965234 7.7987656 16.365234 7.8847656 C 15.936234 7.3707656 15.252406 7.1132812 14.566406 7.1132812 z"
      />
    </Icon>
  );
};

export default TwitterIcon;
