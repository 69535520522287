/* eslint-disable @typescript-eslint/no-explicit-any */
import create from 'zustand';
import { Product } from '../types/subscriptions';

type StoreState = {
  choosenProduct: Product;
  setChoosenProduct: (product: Product) => Product;
  searchTerm: string;
  setSearchTerm: (term: string) => string;
  activeFilters: Array<string>;
  handleSelect: (id: string) => Array<string>;
};

export const useStore = create<StoreState>((set: any) => ({
  choosenProduct: {} as Product,
  setChoosenProduct: (product: Product) => set((state: any) => ({ choosenProduct: product })),
  searchTerm: '',
  setSearchTerm: (term: string) =>
    set((state: any) => ({
      searchTerm: term,
    })),
  activeFilters: [] as Array<string>,
  handleSelect: (id: string) =>
    set((state: any) => ({
      activeFilters: !state.activeFilters.includes(id)
        ? [...state.activeFilters, id]
        : state.activeFilters.filter((currentFilter: string) => currentFilter !== id),
    })),
}));
