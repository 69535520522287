import { useToast } from '@chakra-ui/react';
import { axios } from 'lib/axios';
import { AutocompleteItem } from 'types/autocompleteItem';
import { EducationStatus, PaymentIntervall } from 'types/user';

export const useUserApi = () => {
  const toast = useToast();

  const updateSchoolOfUser = async (item: AutocompleteItem, educationStatus: EducationStatus) => {
    try {
      const resp = await axios.patch('/user/update-school', {
        schoolData: {
          ...item,
        },
        educationStatus,
      });
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {
      toast({
        title: 'Upps',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const updateFirstTimeUse = async () => {
    try {
      const resp = await axios.patch('/user/update/first-time');
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {
      toast({
        title: 'Upps',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const updateIntervall = async (intervall: PaymentIntervall) => {
    try {
      const resp = await axios.patch('/user/update/payment-interval', {
        intervall,
      });
      if (resp.status === 200) {
        return resp.status;
      }
    } catch (error) {
      toast({
        title: 'Upps',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return {
    updateSchoolOfUser,
    updateFirstTimeUse,
    updateIntervall,
  };
};
