import { AxiosResponse } from 'axios';
import { axios } from 'lib/axios';
import { useMutation } from 'react-query';
import { Feedback } from '../../../types/content';

export const addFeedbackMessage = async (message: string): Promise<AxiosResponse<Feedback>> => {
  const id = window.localStorage.getItem('feedback');

  return axios.post(`feedback/message`, { id, message });
};

export const useFeedbackMessage = () => {
  return useMutation({
    onSuccess: () => {
      window.localStorage.removeItem('feedback');
    },
    mutationFn: addFeedbackMessage,
  });
};
