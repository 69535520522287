import { Box, Heading, useToast, Button } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import Joi from 'joi';
import { Form, InputField } from 'components/Form';
import { passwordRegex } from 'config/regex';
import { useAuthApi } from 'hooks/api/auth/useAuthApi';
import { ResetPasswordDto } from 'hooks/api/auth/dto/resetPassword.dto';

const schema = Joi.object({
  password: Joi.string().min(8).pattern(new RegExp(passwordRegex)).required(),
  passwordConfirmation: Joi.any().valid(Joi.ref('password')).required(),
});

type ResetPasswordInputs = {
  password: string;
  passwordConfirmation: string;
  token: string;
};

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const { resetPassword } = useAuthApi();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (!searchParams.get('token')) {
      // TODO: better text
      toast({
        title: 'Upps - Kein Token',
        description: 'Wir bringen dich zurück zum Login.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      setTimeout(() => {
        navigate('/auth/login');
      }, 1000);
    }
  });

  return (
    <Box m={10}>
      <Heading>Passwort zurücksetzen</Heading>
      <Form<ResetPasswordInputs, typeof schema>
        onSubmit={async (values: ResetPasswordDto) =>
          resetPassword({
            ...values,
            token: searchParams.get('token'),
          })
        }
        schema={schema}
      >
        {({ register, formState }: any) => (
          <>
            <InputField
              type="password"
              label="Passwort"
              error={formState.errors.password}
              registration={register('password')}
            />
            <InputField
              type="password"
              label="Passwort wiederholen"
              error={formState.errors.passwordConfirmation}
              registration={register('passwordConfirmation')}
            />
            <Button type="submit">Passwort zurücksetzen</Button>
          </>
        )}
      </Form>
    </Box>
  );
};
