import { Icon, IconProps } from '@chakra-ui/react';

const ClockIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 13 13" {...props}>
      <path
        d="M6.02539 12.2461C9.29492 12.2461 12.002 9.5332 12.002 6.26953C12.002 3 9.28906 0.292969 6.01953 0.292969C2.75586 0.292969 0.0488281 3 0.0488281 6.26953C0.0488281 9.5332 2.76172 12.2461 6.02539 12.2461ZM2.96094 6.90234C2.72656 6.90234 2.55078 6.72656 2.55078 6.49219C2.55078 6.26367 2.72656 6.08789 2.96094 6.08789H5.61523V2.54297C5.61523 2.31445 5.79102 2.13867 6.01953 2.13867C6.24805 2.13867 6.42969 2.31445 6.42969 2.54297V6.49219C6.42969 6.72656 6.24805 6.90234 6.01953 6.90234H2.96094Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default ClockIcon;
