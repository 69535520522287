import { Box, Image, Flex, HStack, Heading } from '@chakra-ui/react';
import Logo from 'assets/logo.png';
import { BackButton } from '../components/General/BackButton';
import { Footer } from '../components/General/Footer';

type FtuLayoutProps = {
  children?: React.ReactNode;
  hasLogo?: boolean;
  hasTitleBar?: boolean;
  title?: string;
};

export const FtuLayout = ({ children, hasLogo = false, hasTitleBar, title }: FtuLayoutProps) => {
  return (
    <Flex
      direction="column"
      align="center"
      minHeight="100vh"
      maxW={{ xl: '1200px' }}
      m="0 auto"
      bg="linear-gradient(90deg, rgba(236,239,252,1) 0%, rgba(244,221,238,1) 84%)"
      position="relative"
    >
      {' '}
      {hasLogo && (
        <Box w="full" height="20vh" display="flex" justifyContent="center" alignItems="center">
          <Image src={Logo} width="220px" />
        </Box>
      )}
      <Box w="100vw" mt="3">
        {hasTitleBar && title && (
          <HStack direction="row" spacing={4} px="5" mx="10px" mb="4">
            <Box w={20}>
              <BackButton />
            </Box>
            <Box w={60}>
              <Heading size="md" textAlign="center" color="brand.200">
                Zahlung
              </Heading>
            </Box>
            <Box w={20} />
          </HStack>
        )}
        {children}
      </Box>
      <Footer />
    </Flex>
  );
};

export default FtuLayout;
