import { Box, Heading, VStack, Text, Button, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import HeartIcon from 'assets/icons/heartIcon';
import { Keyword, ParentFolder } from 'types/content';
import { useAddLikedArticle } from 'hooks/api/user/updateLikedArticles';
import { useRemoveLikedArticle } from 'hooks/api/user/removeLikedArticle';
import ClockIcon from 'assets/icons/ClockIcon';
import { SyntheticEvent } from 'react';
import YearsIcon from 'assets/icons/yearsIcon';
import { logFirebaseEvent } from 'lib/firebaseConfig';
import TagBar from './TagBar';
import { EducationStatus } from '../../types/user';

export type ContentCardProps = {
  id: string;
  title: string;
  isLiked: boolean;
  parentFolders: ParentFolder[];
  link: string;
  keywords: Keyword[];
  readingTime: number | undefined;
  educationStatus: EducationStatus[];
};

export const getEducationStatus = (educationStatus: string[]) => {
  if (educationStatus.length === 1) {
    switch (educationStatus[0]) {
      case 'FIRST':
        return '1 Jahr';
        break;
      case 'SECOND':
        return '2 Jahr';
        break;
      case 'LAST':
        return '3 Jahr';
        break;
      default:
        return '';
    }
  } else if (educationStatus.length === 3) {
    return 'Alle Jahre';
  } else if (educationStatus.includes('FIRST') && educationStatus.includes('SECOND')) {
    return '1 + 2 Jahr';
  } else if (educationStatus.includes('SECOND') && educationStatus.includes('LAST')) {
    return '2 + 3 Jahr';
  } else if (educationStatus.includes('FIRST') && educationStatus.includes('LAST')) {
    return '1 + 3 Jahr';
  } else {
    return '';
  }
};

export const ContentCard = ({
  title,
  isLiked,
  parentFolders,
  link,
  keywords,
  readingTime,
  id,
  educationStatus,
}: ContentCardProps) => {
  const navigate = useNavigate();
  const updateLikedArticlesMutation = useAddLikedArticle();
  const removeLikedArticleMutation = useRemoveLikedArticle();

  const handleClick = async (e: SyntheticEvent<any, Event>) => {
    e.stopPropagation();
    logFirebaseEvent('like', { contentName: title });
    if (isLiked) {
      await removeLikedArticleMutation.mutateAsync(id);
    } else {
      await updateLikedArticlesMutation.mutateAsync(id);
    }
  };

  return (
    <VStack
      boxShadow="md"
      borderRadius="15px"
      pt="50px"
      pl="20px"
      pb="0px"
      pr="10px"
      my="4px"
      bg="white"
      as="div"
      pos="relative"
      height="195px"
      minWidth="280px"
      maxWidth="280px"
      align="left"
      cursor="pointer"
      onClick={async (e) => {
        e.stopPropagation();
        navigate(link);
      }}
    >
      <Box
        as="div"
        bg="brand.200"
        maxWidth="60%"
        minWidth="45%"
        pos="absolute"
        py="8px"
        px="4px"
        left="0"
        top="0"
        borderTopLeftRadius="15px"
        borderBottomRightRadius="15px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize="sm" fontWeight="600" color="brand.50" isTruncated>
          {parentFolders[0].name}
        </Text>
      </Box>
      <Button
        variant="secondary"
        pos="absolute"
        top="0px"
        right="15px"
        onClick={async (e: SyntheticEvent<any, Event>) => handleClick(e)}
      >
        <Center>
          <HeartIcon boxSize="6" color="brand.200" checked={isLiked} />
        </Center>
      </Button>
      <Heading
        as="h4"
        size="md"
        fontWeight="500"
        textAlign="left"
        minHeight="45px"
        maxHeight="60px"
        noOfLines={2}
      >
        {title}
      </Heading>
      <TagBar tags={[...keywords]} pt={keywords.length > 4 ? '10px' : '0px'} />
      <Box
        bg="brand.700"
        w="full"
        pos="absolute"
        bottom="0px"
        left="0px"
        borderBottomRightRadius="15px"
        borderBottomLeftRadius="15px"
      >
        <TagBar
          tags={[
            {
              id: `${readingTime} min.`,
              name: readingTime ? `${readingTime} min.` : `${0} min.`,
              icon: <ClockIcon mr="5px" mb="1px" />,
            },
            {
              id: 'educationStatuss',
              name: getEducationStatus(educationStatus),
              icon: <YearsIcon mr="5px" mb="1px" w="4" h="4" />,
            },
          ]}
          px="20px"
          py="10px"
        />
      </Box>
    </VStack>
  );
};
