import FtuLayout from 'layouts/FtuLayout';
import { Heading, Flex, Text, Box, VStack, Stack, Link } from '@chakra-ui/react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { SlideOver } from 'components/Settings/SlideOver';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ProgressBar } from 'components/welcome/ProgressBar';
import { useStore } from 'store';
import { useSubscriptionIntent } from 'hooks/api/payment/useSubscriptionIntent';
import { CheckoutForm } from 'components/Payment/CheckoutForm';
import { useUserApi } from '../../hooks/api/useUserApi';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_SECRET as string);

export const PaymentMethod = () => {
  const { choosenProduct } = useStore();
  const subscriptionQuery = useSubscriptionIntent(choosenProduct.priceId);
  const { updateFirstTimeUse } = useUserApi();
  const navigate = useNavigate();

  if (!subscriptionQuery.data) return null;

  return (
    <Elements
      stripe={stripe}
      options={{
        clientSecret: subscriptionQuery.data.data.clientSecret,
        appearance: {
          variables: {
            colorText: '#2B3569',
            colorPrimary: '#378567',
            colorIcon: '#2B3569',
          },
        },
      }}
    >
      <FtuLayout hasTitleBar title="Zahlung">
        <SlideOver minHeight="70vh" margin="0px 10px">
          <Box mx="8%">
            <Flex
              bg="brand.100"
              color="brand.50"
              py="3"
              px="5"
              borderRadius="4px"
              justifyContent="space-between"
            >
              <Text align="left" fontWeight="normal">
                {choosenProduct.name}{' '}
                <Text as="span" fontWeight="bold" ml="3">
                  {String(choosenProduct.price).slice(0, 2)},
                  {String(choosenProduct.price).slice(2, 4)} €
                </Text>
              </Text>

              <Box fontWeight="bold" textDecoration="underline">
                <RouterLink to="/app/welcome/payment">Ändern</RouterLink>
              </Box>
            </Flex>
            <VStack mt="16" align="left" spacing="8">
              <Heading fontSize="xl" textAlign="left" color="brand.200">
                Bitte wähle eine Zahlungsart
              </Heading>
              <CheckoutForm />
              <Text color="brand.300" textDecoration="underline" fontWeight="bold" align="center">
                <Stack mt="15px" mb="40px" spacing="4">
                  <Box>
                    <RouterLink to="/app/welcome/voucher">Voucher-Code eingeben</RouterLink>
                  </Box>
                  <Box>
                    <Link
                      onClick={async () => {
                        await updateFirstTimeUse();
                        navigate('/app');
                      }}
                    >
                      Free version testen
                    </Link>
                  </Box>
                </Stack>
              </Text>
            </VStack>
          </Box>
          <ProgressBar
            steps={[
              { route: '/app/welcome', currentActive: false },
              { route: '/app/welcome/payment', currentActive: false },
              { route: '/app/welcome/payment-method', currentActive: true },
            ]}
          />
        </SlideOver>
      </FtuLayout>
    </Elements>
  );
};
