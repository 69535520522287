import { axios } from 'lib/axios';
import { AutocompleteItem } from 'types/autocompleteItem';

export const useSchoolApi = () => {
  const getAutoCompleteItems = async (searchTerm: string): Promise<AutocompleteItem[]> => {
    const result = await axios.get(`/schools?searchTerm=${searchTerm}`);
    return result.data;
  };

  return { getAutoCompleteItems };
};
