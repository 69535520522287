import { UseFormRegisterReturn } from 'react-hook-form';
import { Select } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type SelectOption = {
  value: string;
  label: string;
};

type SelectFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  placeholder: string;
  options: SelectOption[];
  registration: Partial<UseFormRegisterReturn>;
};

export const SelectField: FunctionComponent<SelectFieldProps> = (props: SelectFieldProps) => {
  const { className, label, registration, error, placeholder, options } = props;

  return (
    <FieldWrapper label={label} error={error}>
      <Select
        placeholder={placeholder}
        className={className}
        {...registration}
        bg="brand.50"
        color="brand.200"
        border="1px solid"
        borderColor="brand.500"
      >
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </FieldWrapper>
  );
};
