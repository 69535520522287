import { ReactNode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from 'lib/auth';
import { queryClient } from 'lib/react-query';
import { customTheme } from 'styles/theme';

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={customTheme}>
        <ReactQueryDevtools />
        <AuthProvider>
          <Router>{children}</Router>
        </AuthProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
};
