import { Box, Heading, Button, Stack, Text, Center } from '@chakra-ui/react';
import Joi from 'joi';
import { Form, InputField } from 'components/Form';
import { useAuthApi } from 'hooks/api/auth/useAuthApi';
import AuthLayout from 'layouts/AuthLayout';
import { BackButton } from 'components/General/BackButton';

const schema = Joi.object({
  email: Joi.string()
    .email({
      tlds: {
        allow: false,
      },
    })
    .required(),
});

type ForgotPasswordInputs = {
  email: string;
};

export const ForgotPassword = () => {
  const { sendResetPasswordMail } = useAuthApi();

  return (
    <AuthLayout>
      <Box m={10}>
        <Heading>Passwort vergessen?</Heading>
        <Text>
          Bitte trage hier die E-Mail-Adresse ein, mit der du dich angemeldet hast. Wir werden dir
          anschließend den Link zum Zurücksetzen deines Passworts zusenden.
        </Text>
        <Form<ForgotPasswordInputs, typeof schema>
          onSubmit={async (values: { email: string }) => {
            await sendResetPasswordMail(values);
          }}
          schema={schema}
        >
          {({ register, formState }) => (
            <>
              <InputField
                type="email"
                placeholder="E-Mail"
                error={formState.errors.email}
                registration={register('email')}
              />
              <Stack align="center">
                <Button variant="primary" type="submit">
                  Zurücksetzen
                </Button>
              </Stack>
            </>
          )}
        </Form>
      </Box>
      <Box color="brand.600" mt="100px" alignItems="center">
        <Text>Du hast dein Passwort nicht vergessen?</Text>
        <Center>
          <BackButton />
        </Center>
      </Box>
    </AuthLayout>
  );
};
