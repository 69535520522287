import Joi from 'joi';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  useToast,
  Box,
  Stack,
  Button,
  VStack,
  Heading,
  Text,
  IconButton,
  useBoolean,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Form, InputField } from 'components/Form';
import { passwordRegex } from 'config/regex';
import { useAuth } from 'lib/auth';
import { CheckboxField } from 'components/Form/CheckboxField';
import { RegisterCredentialsDto } from 'lib/dto/RegisterCredentials.dto';
import { errorMessage } from 'components/Form/ErrorMessages';
import AuthLayout from 'layouts/AuthLayout';
import EyeIcon from 'assets/icons/eyeIcon';
import { axios } from '../../../lib/axios';

const schema = Joi.object({
  email: Joi.string()
    .email({
      tlds: {
        allow: false,
      },
    })
    .required()
    .messages({
      'string.empty': errorMessage.empty,
      'string.email': errorMessage.email,
    }),
  password: Joi.string().min(8).pattern(new RegExp(passwordRegex)).required().messages({
    'string.min': errorMessage.pwLength,
    'string.empty': errorMessage.empty,
    'string.pattern.base': errorMessage.pwRequirements,
  }),
  firstName: Joi.string().required().messages({
    'string.empty': errorMessage.empty,
  }),
  lastName: Joi.string().required().messages({
    'string.empty': errorMessage.empty,
  }),
  dataSecurity: Joi.boolean().invalid(false).required().messages({
    'any.invalid': errorMessage.checkboxRequired,
  }),
  agb: Joi.boolean().required().invalid(false).messages({
    'any.invalid': errorMessage.checkboxRequired,
  }),
});

type RegisterInputs = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  dataSecurity: boolean;
  agb: boolean;
};

export const Register: FunctionComponent = () => {
  const [isRegistered, setIsRegistered] = useState(false);
  const { register: registerFn } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [flag, setFlag] = useBoolean();
  const [registeredMail, setRegisteredMail] = useState('');

  return (
    <AuthLayout>
      <Box mt={10} mx={10}>
        <Stack spacing={6} alignItems="center">
          {isRegistered ? (
            <VStack spacing={6} pt="12">
              <Text align="center" fontWeight="600" color="brand.200">
                Wir haben eine Registrierungsmail versendet an
              </Text>
              <Text color="brand.100" fontWeight="600">
                {registeredMail}
              </Text>
              <Text align="center" color="brand.200" pb="12">
                Du wirst automatisch zum Login geleitet. Dort kannst du dich direkt anmelden und mit
                Novaheal lernen.
              </Text>
              <Button variant="primary">
                <Link to="/auth/login">Zum Login</Link>
              </Button>
            </VStack>
          ) : (
            <>
              <Form<RegisterInputs, typeof schema>
                onSubmit={async (values: RegisterCredentialsDto) => {
                  try {
                    const validateMail = await axios.post('/user/validate', {
                      email: values.email,
                    });

                    if (validateMail.data === 'USED') {
                      toast({
                        title: `Die Mail ist bereits vergeben.`,
                        description: 'Bitte nutze eine ander Mail oder logge dich ein.',
                        status: 'error',
                      });
                    } else {
                      await registerFn(values);
                      setRegisteredMail(values.email);
                      setIsRegistered(true);
                      setTimeout(() => {
                        navigate('/auth/login');
                      }, 15000);
                    }
                  } catch (error) {
                    toast({
                      title: `Error! ${error}`,
                      status: 'error',
                    });
                  }
                }}
                schema={schema}
              >
                {({ register, formState }) => (
                  <>
                    <InputField
                      type="email"
                      label="E-Mail"
                      error={formState.errors.email}
                      registration={register('email')}
                    />
                    <InputField
                      type={flag ? 'text' : 'password'}
                      label="Passwort"
                      error={formState.errors.password}
                      registration={register('password')}
                      rightElement={
                        <IconButton
                          aria-label="hide/show"
                          icon={<EyeIcon w="5" h="5" filled={flag} />}
                          onClick={setFlag.toggle}
                          variant="none"
                          color="teal"
                        >
                          {flag ? 'Hide' : 'Show'}
                        </IconButton>
                      }
                    />
                    <InputField
                      type="text"
                      label="Vorname"
                      error={formState.errors.firstName}
                      registration={register('firstName')}
                    />
                    <InputField
                      type="text"
                      label="Nachname"
                      error={formState.errors.lastName}
                      registration={register('lastName')}
                    />
                    <CheckboxField
                      type="boolean"
                      description="Hiermit bestätige ich, dass ich die Datenschutzerklärung gelesen habe und akzeptiere."
                      link="https://novaheal.de/datenschutz/"
                      linkTitle="Datenschutz"
                      error={formState.errors.dataSecurity}
                      registration={register('dataSecurity')}
                    />
                    <CheckboxField
                      type="boolean"
                      description="Hiermit bestätige ich, dass ich die AGBs gelesen habe und akzeptiere."
                      link="https://novaheal.de/agb"
                      linkTitle="AGB"
                      error={formState.errors.agb}
                      registration={register('agb')}
                    />
                    <Stack align="center">
                      <Button variant="primary" type="submit">
                        Registrieren
                      </Button>
                    </Stack>
                  </>
                )}
              </Form>
              <Link to="/auth/login">
                Bereits einen Account? <strong>Einloggen</strong>
              </Link>
            </>
          )}
        </Stack>
      </Box>
    </AuthLayout>
  );
};
