import { Flex, Box, Image, Badge } from '@chakra-ui/react';
import Logo from 'assets/logo.png';
import { Footer } from '../components/General/Footer';

type AuthLayoutProps = {
  children?: React.ReactNode;
};

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <Flex
      direction="column"
      align="center"
      minHeight="100vh"
      maxW={{ xl: '1200px' }}
      m="0 auto"
      bg="linear-gradient(90deg, rgba(236,239,252,1) 0%, rgba(244,221,238,1) 84%)"
      position="relative"
      pb="20vh"
    >
      <Box pos="fixed" left="50%" zIndex="100" top="0" transform="translateX(-50%)">
        <Badge colorScheme="purple">Forschungsversion zur Studie P-ELAN</Badge>
      </Box>
      <Box w="full" height="20vh" display="flex" justifyContent="center" alignItems="center">
        <Image src={Logo} width="220px" />
      </Box>
      {children}
      <Footer />
    </Flex>
  );
};

export default AuthLayout;
