import { Box, Button, Center, Heading, HStack, VStack } from '@chakra-ui/react';
import HappySmileyIcon from 'assets/icons/happySmileyIcon';
import OkSmileyIcon from 'assets/icons/OkSmileyIcon';
import NeutralSmileyIcon from 'assets/icons/NeutralSmileyIcon';
import AngrySmileyIcon from 'assets/icons/AngrySmileyIcon';
import { useState } from 'react';
import Joi from 'joi';
import { useFeedbackMessage } from 'hooks/api/feedback/addFeedbackMessage';
import { useFeedback } from 'hooks/api/feedback/addFeedbackNum';
import { Form, InputField } from '../Form';

type FeedbackFormProps = {
  articleId: string;
};

const schema = Joi.object({
  message: Joi.string().required(),
});

type FeedbackInput = {
  message: string;
};

const getHeading = (num: number): string | undefined => {
  if (num === 0) return 'Wie hat dir dieser Artikel gefallen?';
  if (num === 1) return 'Schön, dass es dir gefällt!';
  if (num === 2) return 'Verbesserungspotenzial?';
  if (num === 3) return 'Wo drückt der Schuh?';
  if (num === 4) return 'Schade';
};

const getLabel = (num: number): string | undefined => {
  if (num === 1) return 'Gibt es vielleicht trotzdem was zu verbessern?';
  if (num === 2) return 'Womit bist du noch nicht ganz glücklich?';
  if (num === 3) return 'Da gibt’s scheinbar ein paar Stellen. Verrate uns doch welche!';
  if (num === 4)
    return 'Sag uns was dich stört und wir versuchen dir und vielen Anderen zu helfen!';
};

export const FeedbackForm = ({ articleId }: FeedbackFormProps) => {
  const [feedBack, setFeedback] = useState(0);
  const addFeebackVotingMutation = useFeedback();
  const addFeedbackMessageMutation = useFeedbackMessage();

  const handleSmileyClick = async (num: number) => {
    await addFeebackVotingMutation.mutateAsync({ articleId, votingNumber: num });
    setFeedback(num);
  };

  return (
    <VStack
      bg="brand.400"
      borderBottomLeftRadius="10px"
      borderBottomRightRadius="10px"
      p="4"
      borderTop="1px solid"
      borderColor="brand.500"
    >
      <Heading size="md" color="brand.200">
        {getHeading(feedBack)}
      </Heading>
      {feedBack === 0 ? (
        <HStack mt="4" justifyContent="space-between" minWidth="280px">
          <Box
            bg="#84AB64"
            color="brand.50"
            py="3"
            px="5"
            borderRadius="10px"
            onClick={async () => handleSmileyClick(1)}
          >
            <Center>
              <HappySmileyIcon w="20px" h="20px" />
            </Center>
          </Box>
          <Box
            bg="#E7C05B"
            color="brand.50"
            py="3"
            px="5"
            borderRadius="10px"
            onClick={async () => handleSmileyClick(2)}
          >
            <Center>
              <OkSmileyIcon w="20px" h="20px" />
            </Center>
          </Box>
          <Box
            bg="#EAAB4D"
            color="brand.50"
            py="3"
            px="5"
            borderRadius="10px"
            onClick={async () => handleSmileyClick(3)}
          >
            <Center>
              <NeutralSmileyIcon w="20px" h="20px" />
            </Center>
          </Box>
          <Box
            bg="#CB4A4A"
            color="brand.50"
            py="3"
            px="5"
            borderRadius="10px"
            onClick={async () => handleSmileyClick(4)}
          >
            <Center>
              <AngrySmileyIcon w="20px" h="20px" />
            </Center>
          </Box>
        </HStack>
      ) : (
        <Form<FeedbackInput, typeof schema>
          schema={schema}
          onSubmit={async (values: { message: string }) => {
            await addFeedbackMessageMutation.mutateAsync(values.message);
            setFeedback(0);
          }}
        >
          {({ register, formState }) => (
            <VStack mt="10px" spacing="22px">
              <InputField
                type="text"
                label={getLabel(feedBack)}
                error={formState.errors.message}
                registration={register('message')}
                placeholder="Deine Nachricht "
              />
              <Button type="submit" variant="primary">
                Feedback senden
              </Button>
            </VStack>
          )}
        </Form>
      )}
    </VStack>
  );
};
