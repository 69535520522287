import { extendTheme } from '@chakra-ui/react';
import { ButtonStyles as Button } from './components/buttonStyles';

export const customTheme = extendTheme({
  colors: {
    brand: {
      50: '#FFFFFF',
      100: '#378567',
      200: '#2B3569',
      300: '#845DA5',
      400: '#EFF0F5',
      500: '#9C9FB0',
      600: '#5E6562',
      700: '#F1F3FD',
      800: '#6F5288',
      900: '#f7f9fd',
    },
  },
  components: {
    Button,
  },
});
