import { Box, Stack, Heading, Link, Text, Flex, Spacer, Center, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from 'components/welcome/ProgressBar';
import { useUserApi } from 'hooks/api/useUserApi';
import { queryClient } from 'lib/react-query';
import FtuLayout from 'layouts/FtuLayout';
import { SlideOver } from 'components/Settings/SlideOver';
import { addSubscriptionIntent } from '../../hooks/api/payment/useSubscriptionIntent';
import { useAllProducts } from '../../hooks/api/payment/products/useAllProducts';
import { Product } from '../../types/subscriptions';
import { useStore } from '../../store';

export const Payment = () => {
  const navigate = useNavigate();
  const { updateFirstTimeUse } = useUserApi();
  const useProductsQuery = useAllProducts();
  const { choosenProduct, setChoosenProduct } = useStore();

  if (!useProductsQuery.data) return null;

  const sortedProducts = useProductsQuery.data.sort(
    (p1: Product, p2: Product) => p1.price - p2.price
  );

  return (
    <FtuLayout hasTitleBar title="Zahlung">
      <SlideOver minHeight="70vh" margin="0px 10px" height="calc(100vh - 100px)">
        <Box mt="10px">
          <Heading textAlign="left" mb="10px" mx="10%" size="lg" color="brand.200">
            Bitte wähle einen Zahlungsintervall
          </Heading>
          <Stack spacing="2" mx="8%" mt="20px">
            {sortedProducts.map((product: Product) => (
              <Flex
                key={product.productId}
                bg={choosenProduct.priceId === product.priceId ? 'brand.200' : 'gray.200'}
                color={choosenProduct.priceId === product.priceId ? 'brand.50' : 'brand.200'}
                py="3"
                px="20%"
                borderRadius="4px"
                onClick={() => setChoosenProduct(product)}
              >
                <Text align="center" fontWeight="bold">
                  {product.name}{' '}
                </Text>
                <Spacer />
                <Text align="center" fontWeight="bold">
                  {String(product.price).slice(0, 2)},{String(product.price).slice(2, 4)} €
                </Text>
              </Flex>
            ))}
          </Stack>
        </Box>
        <Stack mt="40px" spacing="4">
          <Center>
            <Button
              variant="primary"
              type="submit"
              disabled={!choosenProduct.priceId}
              onClick={async () => {
                if (choosenProduct) {
                  await queryClient.prefetchQuery('subscription', () =>
                    addSubscriptionIntent(choosenProduct.priceId)
                  );
                }
                navigate('/app/welcome/payment-method');
              }}
            >
              Weiter
            </Button>
          </Center>
          <Text color="brand.300" textDecoration="underline" fontWeight="bold" align="center">
            <Stack mt="15px" mb="40px" spacing="4">
              <Box>
                <Link
                  onClick={async () => {
                    navigate('/app/welcome/voucher');
                  }}
                >
                  Voucher-Code eingeben
                </Link>
              </Box>
              <Box>
                <Link
                  onClick={async () => {
                    await updateFirstTimeUse();
                    navigate('/app');
                  }}
                >
                  Free version testen
                </Link>
              </Box>
            </Stack>
          </Text>
        </Stack>
        <ProgressBar
          steps={[
            { route: '/app/welcome', currentActive: false },
            { route: '/app/welcome/payment', currentActive: true },
            { route: '/app/welcome/payment-method', currentActive: false },
          ]}
        />
      </SlideOver>
    </FtuLayout>
  );
};
