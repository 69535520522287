import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const BackButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      variant="secondary"
      verticalAlign="middle"
      fontWeight="300"
      px="0"
      ml="-20px"
      onClick={() => navigate(-1)}
    >
      <ChevronLeftIcon w="6" h="5" />
      Zurück
    </Button>
  );
};
