import { useQuery } from 'react-query';
import { axios } from 'lib/axios';

export const getAllProducts = async () => {
  const products = await axios.get(`/subscriptions/products`);
  return products.data;
};

export const useAllProducts = () => {
  return useQuery({
    queryKey: ['products'],
    queryFn: () => getAllProducts(),
  });
};
