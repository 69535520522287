import { Box } from '@chakra-ui/react';

type SlideOverProps = {
  children: React.ReactNode;
  minHeight?: string;
  margin?: string;
  height?: string;
};

export const SlideOver = ({ children, minHeight, margin, height }: SlideOverProps) => {
  return (
    <Box
      bg="brand.50"
      w="auto"
      borderTopLeftRadius={20}
      borderTopRightRadius={20}
      py={8}
      minHeight={minHeight}
      margin={margin}
      h={height}
    >
      {children}
    </Box>
  );
};
