/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useEffect, useState } from 'react';
import { Input, Box, List, ListItem, SlideFade, useBoolean } from '@chakra-ui/react';
import { UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { AutocompleteItem } from 'types/autocompleteItem';
import { FieldWrapper, FieldWrapperPassThroughProps } from 'components/Form/FieldWrapper';

type AutocompleteFieldProps = FieldWrapperPassThroughProps & {
  placeholder?: string;
  getAutocompleteItems: (searchTerm: string) => Promise<AutocompleteItem[]>;
  registrationName: string;
  idName: string;
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<any>;
  getValues: UseFormGetValues<any>;
  initalName?: string;
  initalId?: string;
};

export const AutocompleteField = (props: AutocompleteFieldProps) => {
  const {
    placeholder,
    label,
    error,
    getAutocompleteItems,
    registrationName,
    idName,
    register,
    setValue,
    getValues,
    initalId,
    initalName,
  } = props;
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<AutocompleteItem[]>([]);
  const [showCreateNew, setshowCreateNew] = useBoolean();

  useEffect(() => {
    if (initalId && initalName) {
      setValue(idName, initalId);
      setValue(registrationName, initalName);
    }
  }, [initalId, initalName, setValue, idName, registrationName]);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setValue(idName, null);
    const userInput: string = e.target.value;
    const suggestions = await getAutocompleteItems(userInput);
    setFilteredSuggestions(suggestions);
    setShowSuggestions(true);
    setValue(registrationName, userInput, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setshowCreateNew.on();
  };

  // TODO: Find better type definition for e
  const handleClick = (e: any, item?: AutocompleteItem) => {
    if (filteredSuggestions.length === 0) {
      setshowCreateNew.off();
    }
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    if (item) {
      setValue(registrationName, item?.name, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue(idName, item?.id, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      setValue(registrationName, getValues(registrationName), {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  return (
    <Box>
      <FieldWrapper label={label} error={error}>
        <Input
          type="text"
          placeholder={placeholder}
          {...register(registrationName, {
            onChange: (e: any) => {
              e.preventDefault();
              handleChange(e);
            },
          })}
          bg="brand.50"
          color="brand.200"
          border="1px solid"
          borderColor="brand.500"
        />
      </FieldWrapper>
      {showSuggestions && getValues(registrationName) && (
        <Box position="relative">
          <SlideFade in={showSuggestions && getValues(registrationName) !== ''} offsetY="-0px">
            <Box
              ml={1}
              boxShadow="md"
              py="4"
              px="2"
              rounded="md"
              bg="white"
              zIndex="2"
              maxHeight="200"
              overflowY="scroll"
              position="absolute"
              width="98%"
            >
              <List spacing="2">
                {showCreateNew && (
                  <ListItem
                    onClick={(e) => {
                      handleClick(e);
                    }}
                    p="2"
                  >
                    Neu anlegen: {getValues(registrationName)}
                  </ListItem>
                )}
                {filteredSuggestions.map((item) => (
                  <ListItem
                    key={item.id}
                    onClick={(e) => {
                      handleClick(e, item);
                    }}
                    p="2"
                    bg="gray.100"
                  >
                    {item.name}
                  </ListItem>
                ))}
              </List>
            </Box>
          </SlideFade>
        </Box>
      )}
    </Box>
  );
};
