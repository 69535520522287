import { initReactQueryAuth } from 'react-query-auth';
import { User } from 'types/user';
import storage from 'utils/storage';
import { logFirebaseEvent } from './firebaseConfig';
import { axios } from './axios';
import { LoginCredentialsDto } from './dto/LoginCredentials.dto';
import { RegisterCredentialsDto } from './dto/RegisterCredentials.dto';

// TODO: add return value
function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

async function loginFn(data: LoginCredentialsDto): Promise<User> {
  const response = await axios.post('/auth/login', data);
  const { updatedUser, accessToken } = response.data;
  storage.setToken(accessToken);
  logFirebaseEvent('login');
  return updatedUser;
}

async function logoutFn(): Promise<User> {
  const response = await axios.post('/auth/logout');
  storage.clearToken();
  logFirebaseEvent('logout');
  return response.data;
}

async function registerFn(data: RegisterCredentialsDto): Promise<User> {
  const response = await axios.post('/auth/register', {
    ...data,
    acceptedDataPrivacy: data.dataSecurity,
    acceptedAgb: data.agb,
  });
  logFirebaseEvent('sign_up');
  return response.data;
}

export async function refreshUserToken(): Promise<boolean> {
  try {
    const response = await axios.get('/auth/refresh');
    if (response.status === 200) {
      storage.setToken(response.data);
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

async function loadUser(): Promise<User | null> {
  try {
    if (storage.getToken()) {
      const token = parseJwt(storage.getToken());
      if (Date.now() >= token.exp * 1000) {
        const hasToken = await refreshUserToken();
        if (!hasToken) return null;
      }
      const response = await axios.get('/user/me');
      return response.data;
    }
    const hasToken = await refreshUserToken();
    if (!hasToken) return null;
    const response = await axios.get('/user/me');
    return response.data;
  } catch (error) {
    return null;
  }
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  User | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  LoginCredentialsDto,
  RegisterCredentialsDto
>(authConfig);
