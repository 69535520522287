export const errorMessage = {
  empty: 'Bitte angeben.',
  email: 'Bitte eine gültige E-Mail-Adresse angeben.',
  pwLength: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
  pwRequirements: 'Das Passwort muss mindestens enthalten: einen Großbuchstaben, eine Zahl.',
  checkboxRequired: 'Bitte bestätigen.',
  toastLogin: {
    ErrorTitle: 'Der Login hat nicht funktioniert.',
    ErrorDescription: 'E-Mail unbekannt oder Passwort falsch.',
  },
};
